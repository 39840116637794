import { Pipe, PipeTransform } from '@angular/core';
import { dateDropdownOptions } from '../services/statistics.service';

@Pipe({
  name: 'dateCreationPeriod',
})
export class DateCreationPeriodPipe implements PipeTransform {
  transform(
    dateCreation: string | null,
    creation_from: string | null,
    creation_to: string | null
  ): string {
    if (dateCreation === 'choose') {
      if (creation_from === null && creation_to === null) {
        return '-';
      } else if (creation_from === null) {
        return `Μέχρι ${creation_to}`;
      } else if (creation_to === null) {
        return `Από ${creation_from}`;
      } else {
        return creation_from + ' - ' + creation_to;
      }
    } else {
      return dateDropdownOptions.filter(
        (option) => option.type === dateCreation
      )[0].message;
    }
  }
}
