import { LoginService } from './../../../services/login.service';
import { EntityService } from './../../../services/entity.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Field } from 'src/app/model';

@Component({
  selector: 'app-field-selector',
  templateUrl: './field-selector.component.html',
  styleUrls: ['./field-selector.component.css'],
})
export class FieldSelectorComponent implements OnInit {
  entityCode: string;
  fields: Field[] = [];
  displayedColumns: string[] = ['id', 'name', 'type', 'code'];
  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<FieldSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private entityService: EntityService,
    private loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.entityCode = this.data.entity;
    this.getFilelds();
  }

  private getFilelds() {
    this.entityService
      .getFldsEntityByName(this.entityCode)
      .pipe(take(1))
      .subscribe((res) => {
        this.fields = res.fields;
      });
  }

  public fldNameTranslation(field: Field): string {
    let lang = this.loginService.getLoginUser().lang;
    return lang == 'en' ? field.label_en : field.label_gr;
  }

  public fldTypeTranslation(field: Field): string {
    let lang = this.loginService.getLoginUser().lang;
    return lang == 'en' ? field.field_specs.name_en : field.field_specs.name_gr;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));
}
