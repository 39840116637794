import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { CostCenter } from '../model';

@Injectable({
  providedIn: 'root',
})
export class CostCenterService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  fetchCostCenters() {
    return this.get<CostCenter[]>(this.apiUrl + 'cost-centers');
  }

  createCostCenter(newCostCenter: any) {
    return this.post<any>(this.apiUrl + 'cost-centers', newCostCenter);
  }

  editCostCenter(newCostCenter: any) {
    return this.put<any>(this.apiUrl + 'cost-centers', newCostCenter);
  }

  deleteCostCenter(costCenterId: number) {
    return this.delete<any>(this.apiUrl + `cost-centers/${costCenterId}`);
  }
}
