import { User } from '../model';
import { ERPMoneyaccount } from './erpmoneyaccount';
import { ERPMoneydoctype } from './erpmoneydoctype';
import { ERPSpdoctype } from './erpspdoctype';
import { ERPSpdocline } from './spdocline';

export class ERPMoneydoc {
  id: number;
  code: string;
  moneydoctype_id: number;
  moneydoctype: ERPMoneydoctype;
  transactor_id: number;
  account_name: string;
  supplier_name: string;
  owner_id: number;
  owner: User;
  moneyaccount_id: number;
  moneyaccount: ERPMoneyaccount;
  year: number;
  paid_at: string;
  total_price: number;
  created_at: Date;
  updated_at: Date;
  to_acc_id: number;
  remarks: string;
}
