<mat-card>
  <div class="fullDiv flex">
    <div class="sideBar background-alternate-warn">&nbsp;</div>
    <div class="rightDiv" cdkDropListGroup>
      <div class="spintDiv">
        <div class="text-primary flex connctedTitle">
          <div class="sprintCircle background-accent"></div>
          <div>
            <div>{{ 'Approvals.createRequest' | translate }}</div>
            <div class="createDate" *ngIf="ASSIGNEES.length > 0">
              {{ ASSIGNEES[0].created_at | date: 'y-M-d HH:mm' }}
            </div>
          </div>
        </div>
      </div>

      <div class="spintDiv" *ngFor="let assignee of ASSIGNEES">
        <div class="text-primary flex connctedTitle">
          <div
            class="sprintCircle background-primary"
            [ngClass]="{
              'background-primary': assignee.decision == 1,
              'decline-background': assignee.decision == 2,
              'pending-background': assignee.decision == null
            }"
          ></div>
          <div>
            <div class="flex">
              <app-show-avatar
                [SHOWCONNECTIVITY]="true"
                [USER]="assignee.assignee"
              >
              </app-show-avatar>
              <span class="assigneeName"
                >{{ assignee.assignee.firstname }}
                {{ assignee.assignee.lastname }} -</span
              >
              <strong>
                <span *ngIf="assignee.decision == 1">{{
                  'Approvals.approval' | translate
                }}</span>
                <span *ngIf="assignee.decision == 2">{{
                  'Approvals.rejection' | translate
                }}</span>
                <span *ngIf="assignee.decision == null">{{
                  'Approvals.waitingForApproval' | translate
                }}</span>
              </strong>
            </div>
            <div class="timelineDate">
              <span *ngIf="assignee.decision">
                {{ assignee.decided_at | date: 'y-M-d HH:mm' }}
              </span>
            </div>
            <div
              *ngIf="assignee.decision != null"
              style="max-width: 400px; font-size: 12px"
            >
              <div style="font-weight: bold">
                {{ 'Approvals.decisionNotes' | translate }}:
              </div>
              <p ItemLink>
                <span>{{ assignee.message }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="alternate-theme"></div>
    </div>
  </div>
</mat-card>
