import { Injectable } from '@angular/core';
import { OverlayRef, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { scan, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SpinnerComponent } from '../common/spinner/spinner.component';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private spinnerRef: OverlayRef = this.cdkSpinnerCreate();
  public loaderReq: number = 0;
  spin$: Subject<boolean> = new Subject();
  constructor(private overlay: Overlay) {
    this.spin$
      .asObservable()
      .pipe(
        map((val) => (val ? 1 : -1)),
        scan((acc, one) => (acc + one >= 0 ? acc + one : 0), 0)
      )
      .subscribe((res) => {
        if (res === 1) {
          this.showSpinner();
        } else if (res == 0) {
          this.loaderReq--;

          this.spinnerRef.hasAttached() ? this.stopSpinner() : null;
        }
      });
  }

  private cdkSpinnerCreate() {
    return this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });
  }
  showSpinner() {
    this.loaderReq++;
    if (this.spinnerRef.hasAttached()) {
      return;
    }
    this.spinnerRef.attach(new ComponentPortal(SpinnerComponent));
  }
  stopSpinner() {
    this.loaderReq--;
    if (this.loaderReq == 0) {
      this.spinnerRef.detach();
    }
  }
}
