import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import {
  PagedData,
  Workflow,
  WorkflowDetails,
  WorkflowTemplate,
} from '../model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkflowService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  templateForEdit: WorkflowTemplate | null = null;
  workflowTemplateStepsIdIndexMapping = new Map();
  createNewRecordForWorkflow = false;

  getWorkflowTemplates(
    pageIndex: number,
    pageSize: number,
    status: string
  ): Observable<PagedData<WorkflowTemplate>> {
    let url =
      this.apiUrl + `workflowTemplates?page=${pageIndex}&pageSize=${pageSize}`;
    url += status !== 'null' ? `&status=${status}` : '';
    return this.get<PagedData<WorkflowTemplate>>(url);
  }

  getWorkflowTemplate(
    workflowTemplateId: number
  ): Observable<WorkflowTemplate> {
    return this.get<WorkflowTemplate>(
      this.apiUrl + `workflowTemplate/${workflowTemplateId}`
    );
  }

  createNewWorkflowTemplate(title: string): Observable<any> {
    return this.post<any>(this.apiUrl + 'workflowTemplates/create', { title });
  }

  updateNewWorkflowTemplate(
    workflowTemplateId: number,
    workflowTemplateValues: { title: string; status: string }
  ): Observable<any> {
    return this.put<any>(
      this.apiUrl + `workflowTemplates/update/${workflowTemplateId}`,
      {
        title: workflowTemplateValues.title,
        status: workflowTemplateValues.status,
      }
    );
  }

  deleteWorkflowTemplate(workflowTemplateId: number): Observable<any> {
    return this.delete<any>(
      this.apiUrl + `workflowTemplates/delete/${workflowTemplateId}`
    );
  }

  createWorkflowTemplateStep(
    workflowTemplateId: number,
    newTemplateStepValues: any
  ): Observable<any> {
    return this.post<any>(
      this.apiUrl + `workflowTemplates/create-step/${workflowTemplateId}`,
      this.createWorkflowValuesObject(newTemplateStepValues)
    );
  }

  updateWorkflowTemplateStep(
    workflowTemplateStepId: number,
    newTemplateStepValues: any
  ): Observable<any> {
    return this.put<any>(
      this.apiUrl + `workflowTemplates/update-step/${workflowTemplateStepId}`,
      this.createWorkflowValuesObject(newTemplateStepValues)
    );
  }

  deleteWorkflowTemplateStep(workflowTemplateStepId: number): Observable<any> {
    return this.delete<any>(
      this.apiUrl + `workflowTemplates/delete-step/${workflowTemplateStepId}`
    );
  }

  duplicateWorkflowTemplate(workflowTemplateId: number): Observable<any> {
    return this.post<any>(
      this.apiUrl + `workflowTemplates/duplicate/${workflowTemplateId}`,
      {}
    );
  }

  getWorkflows(
    pageIndex: number,
    pageSize: number,
    status: string,
    ownerId: number | undefined
  ): Observable<PagedData<Workflow>> {
    let url = this.apiUrl + `workflows/?page=${pageIndex}&pageSize=${pageSize}`;
    url += status !== 'null' ? `&status=${status}` : '';
    url += ownerId ? `&ownerId=${ownerId}` : '';
    return this.get<PagedData<Workflow>>(url);
  }

  getWorkflow(workflowId: number): Observable<WorkflowDetails> {
    return this.get<WorkflowDetails>(this.apiUrl + `workflow/${workflowId}`);
  }

  createWorkflow(workflowValues: {
    workflowTemplateId: number;
    title: string;
  }) {
    return this.post<any>(this.apiUrl + `workflows/create`, {
      title: workflowValues.title,
      workflowTemplateId: workflowValues.workflowTemplateId,
    });
  }

  assignEntdataToWorkflow(workflowStepId: number, entdataId: number) {
    return this.post<any>(this.apiUrl + `workflows/assign-entdata`, {
      workflowStepId,
      entdataId,
    });
  }

  private createWorkflowValuesObject(newTemplateStepValues: any) {
    return {
      previousStep:
        newTemplateStepValues.previousStep !== 'null'
          ? newTemplateStepValues.previousStep
          : null,
      entdataStep: newTemplateStepValues.entdataStep ?? null,
      entityChoiceId: newTemplateStepValues.entityChoiceId ?? null,
      questionEntfieldId: newTemplateStepValues.questionEntfieldId ?? null,
      actorId: newTemplateStepValues.actorId,
      stepType: newTemplateStepValues.stepType,
      actionTitle: newTemplateStepValues.actionTitle,
    };
  }
}
