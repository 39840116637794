import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Field } from '../../model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EntitySelectorDiagComponent } from '../entity-selector-diag/entity-selector-diag.component';
import { EntityService } from '../../services/entity.service';

@Component({
  selector: 'app-entity-selector-diag-multiple',
  templateUrl: './entity-selector-diag-multiple.component.html',
  styleUrls: ['./entity-selector-diag-multiple.component.css'],
})
export class EntitySelectorDiagMultipleComponent implements OnInit {
  entityCode: string;
  searchFields: Field[];
  selectedMultipleIds: number[] = [];

  @ViewChild('dataList') dataList: any;
  constructor(
    private entityService: EntityService,
    private dialogRef: MatDialogRef<EntitySelectorDiagComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.entityCode = this.data.entityCode;
    this.searchFields = this.data.searchFields;
    this.entityService.selectedItemIds.subscribe({
      next: (value) => (this.selectedMultipleIds = value),
    });
  }

  selectSingleItem(ev) {
    this.dialogRef.close([ev.id]);
  }

  selectMultipleItems() {
    this.dialogRef.close(this.selectedMultipleIds);
  }
}
