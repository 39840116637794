<div class="docFld" style="display: flex; align-items: center">
  <span *ngIf="spdoc.account_name"
    ><strong>{{ 'ERP.account' | translate }}</strong
    >: {{ spdoc.account_name }}</span
  >
  <span *ngIf="spdoc.supplier_name"
    ><strong>{{ 'ERP.supplier' | translate }}</strong
    >: {{ spdoc.supplier_name }}</span
  >
  <span *ngIf="spdoc.transactor_id"
    ><button mat-icon-button color="warn" (click)="clearTransactor()">
      <mat-icon>clear</mat-icon>
    </button></span
  >

  <div
    *ngIf="!spdoc.transactor_id"
    style="z-index: 1000; background-color: white"
  >
    <app-entity-selector-autocomplete
      *ngIf="ransactorSource == 'account'"
      [ENTITY]="'account'"
      LABEL="{{ 'ERP.account' | translate }}"
      (selectEntityItem)="fillTransactorItem($event)"
      [DISABLED]="!spdoc.spdoctype_id"
    >
    </app-entity-selector-autocomplete>

    <app-entity-selector-autocomplete
      *ngIf="ransactorSource == 'supplier'"
      [ENTITY]="'supplier'"
      LABEL="{{ 'ERP.supplier' | translate }}"
      (selectEntityItem)="fillTransactorItem($event)"
      [DISABLED]="!spdoc.spdoctype_id"
    >
    </app-entity-selector-autocomplete>
  </div>
</div>

<p style="margin-top: 10px">
  {{ 'ERP.doc_generic' | translate }}
  <mat-divider class="itemDivider"></mat-divider>
</p>

<div style="display: flex; gap: 15px">
  <mat-form-field class="docFld">
    <mat-label class="title">{{ 'ERP.address_send' | translate }}</mat-label>
    <input matInput [(ngModel)]="spdoc.post_address" />
  </mat-form-field>

  <mat-form-field class="docFld">
    <mat-label class="title">{{ 'ERP.address_del' | translate }}</mat-label>
    <input matInput [(ngModel)]="spdoc.del_address" />
  </mat-form-field>

  <mat-form-field class="remarks">
    <mat-label class="title">{{ 'ERP.remarks' | translate }}</mat-label>
    <input matInput [(ngModel)]="spdoc.remarks" />
  </mat-form-field>
</div>
