import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EntitySelectorDiagComponent } from 'src/app/common/entity-selector-diag/entity-selector-diag.component';
import {
  CalendarEntityEvent,
  CalendarEventParticipant,
  User,
} from 'src/app/model';
import { EntityService } from 'src/app/services/entity.service';
import { UserService } from '../../../../../../../services/user.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-event-team',
  templateUrl: './event-team.component.html',
  styleUrls: ['./event-team.component.css'],
})
export class EventTeamComponent implements OnInit {
  @Input() EVENT: CalendarEntityEvent;
  @Input() ITEMID: any;
  @Input() disabled: boolean = false;
  participants: User[] = [];
  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    private entityService: EntityService
  ) {}

  ngOnInit(): void {
    if (this.EVENT.id) {
      this.getParticipants();
    } else {
      this.EVENT.calendar_event_participants = [];
    }
  }

  public selectEntityItemDiag() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      entityCode: 'user',
    };

    const dialogRef = this.dialog.open(
      EntitySelectorDiagComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        let memberIndex = this.EVENT.calendar_event_participants.findIndex(
          (x) => x.user_id == result.id
        );
        if (memberIndex < 0) {
          let newPart: CalendarEventParticipant =
            new CalendarEventParticipant();
          newPart.user_id = result.id;
          newPart.user = result;
          this.EVENT.calendar_event_participants.push(newPart);
          let userId = result.id.toString();
          this.userService
            .getUserCommon(userId)
            .pipe(take(1))
            .subscribe((res) => {
              this.participants.push(res);
            });
        }
      }
    });
  }

  private getParticipants() {
    this.participants = [];
    this.EVENT.calendar_event_participants.forEach(($participant) => {
      let userId = $participant.user_id.toString();
      this.userService
        .getUserCommon(userId)
        .pipe(take(1))
        .subscribe((res) => {
          this.participants.push(res);
        });
    });
  }

  public removeMember(index: number) {
    this.EVENT.calendar_event_participants.splice(index, 1);
    this.participants.splice(index, 1);
  }
}
