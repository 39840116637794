import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Department } from 'src/app/model';

@Component({
  selector: 'app-department-list',
  templateUrl: './department-list.component.html',
  styleUrls: ['./department-list.component.css'],
})
export class DepartmentListComponent implements OnInit {
  @Input() departments: Department[] = [];
  @Output() editDepartment = new EventEmitter<Department>();
  @Output() delDepartment = new EventEmitter<Department>();

  displayedColumns: string[] = ['id', 'title', 'location', 'parent', 'action'];

  constructor() {}

  ngOnInit(): void {}

  public edit(department: Department) {
    this.editDepartment.emit(department);
  }

  public delete(department: Department) {
    this.delDepartment.emit(department);
  }
}
