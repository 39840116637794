import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PagedData } from 'src/app/model';
import { ERPSpdocline } from 'src/app/models/spdocline';
import { ErpWarehouseService } from 'src/app/services/erp/erp-warehouse.service';
import { BaseFilter } from '../../filters';
import { LayoutService } from '../../../services/layout.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-warehouse-product-history',
  templateUrl: './warehouse-product-history.component.html',
  styleUrls: ['./warehouse-product-history.component.css'],
})
export class WarehouseProductHistoryComponent implements OnInit {
  public lines: PagedData<ERPSpdocline> = new PagedData();
  public gridColumns: any[] = [];
  public filters: BaseFilter = new BaseFilter();
  private product_id: number;
  private warehouse_id: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private warehouseService: ErpWarehouseService,
    public layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.getGridColumns();

    this.product_id = this.data.product_id;
    this.warehouse_id = this.data.warehouse_id;

    this.getHistory();
  }

  private getGridColumns() {
    this.gridColumns = [
      {
        title: this.translateService.instant('ERPSpdoctype.code'),
        attr: 'code',
        view: true,
        mobileView: false,
        type: 'data',
      },
      {
        title: this.translateService.instant('QuoteConfigurator.qty'),
        attr: 'qty1',
        view: true,
        mobileView: true,
        type: 'data',
      },
      {
        title: this.translateService.instant(
          'ERPWarehouse.progressive_inventory'
        ),
        attr: 'progressive_inventory',
        view: true,
        mobileView: true,
        type: 'data',
      },
    ];
  }

  private getHistory() {
    this.warehouseService
      .getProductWhHistory(this.filters, this.product_id, this.warehouse_id)
      .pipe(take(1))
      .subscribe((res) => {
        this.lines = res;
      });
  }

  public refreshList(filter: BaseFilter) {
    this.filters = filter;
    this.getHistory();
  }
}
