<mat-form-field class="full-width">
  <mat-label class="title"
    >{{ title }}<span *ngIf="isMandatory == '1'">*</span>
  </mat-label>
  <mat-select [(ngModel)]="ITEM.value" class="full-width" [disabled]="disabled">
    <mat-option *ngFor="let option of options" [value]="option">
      {{ option }}
    </mat-option>
  </mat-select>
</mat-form-field>
