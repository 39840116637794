import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-simple-alert-diag',
  templateUrl: './simple-alert-diag.component.html',
  styleUrls: ['./simple-alert-diag.component.css'],
})
export class SimpleAlertDiagComponent implements OnInit {
  title: string;
  message: string;
  constructor(
    private dialogRef: MatDialogRef<SimpleAlertDiagComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.title = this.data.title;
    this.message = this.data.message;
  }
}
