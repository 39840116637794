import { NotifyComponent } from '../common/notify/notify.component';
import { Notify, ReturnData } from '../model';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerType } from '../common/enums';
import { Platform } from '@angular/cdk/platform';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseService {
  public notification$: Subject<Notify> = new Subject();
  public openApproval = new BehaviorSubject<number>(null);
  public openCalendarEvent = new BehaviorSubject<number>(null);
  public openChooseEntityComponent = new Subject();

  public welcomeMessage: string = this.isAm()
    ? 'welcome.goodmorning'
    : 'welcome.goodafternoon';

  constructor(
    private httpClient: HttpClient,
    private snackBar: MatSnackBar,
    public translateService: TranslateService,
    public platform: Platform
  ) {
    super(httpClient);

    this.notification$.subscribe((notify) => {
      this.snackBar.openFromComponent(NotifyComponent, {
        data: {
          notify: notify,
          preClose: () => {
            this.snackBar.dismiss();
          },
        },
        duration: 3000,
      });
    });
  }

  showSnackbarMessage(
    message: string,
    action = 'ok',
    config: MatSnackBarConfig = { duration: 1500 }
  ) {
    this.snackBar.open(this.translateService.instant(message), action, config);
  }

  showSnackbarMessageWithoutTranslation(
    message: string,
    action = 'ok',
    config: MatSnackBarConfig = { duration: 1500 }
  ) {
    this.snackBar.open(message, action, config);
  }

  showWelcomeMessage(message: string) {
    this.snackBar.open(
      this.translateService.instant(this.welcomeMessage) + ' ' + message,
      '',
      {
        panelClass: 'welcome-snackbar',
        duration: 3000,
        verticalPosition: 'top',
      }
    );
  }

  public getNotifications(): Observable<Notify[]> {
    return this.get<Notify[]>(this.apiUrl + 'user/notifications');
  }
  public readNotification(notification: Notify): Observable<ReturnData<any>> {
    return this.get<ReturnData<any>>(
      this.apiUrl + 'user/notifications/read/' + notification.id,
      SpinnerType.None
    );
  }

  public readAllNotifications(): Observable<ReturnData<any>> {
    return this.post<ReturnData<any>>(
      this.apiUrl + 'user/notifications/read-all/',
      {},
      SpinnerType.None
    );
  }

  public sendLiveWebNotif(title: string, body: string) {
    try {
      if (this.platform.SAFARI || this.platform.IOS) {
        var notification = new Notification(title, {
          body: body,
          icon:
            window.location.protocol +
            '//' +
            window.location.hostname +
            '/assets/icons/touch-icon-ipad.png',
          dir: 'auto',
        });

        setTimeout(function () {
          notification.close();
        }, 4000);
      }
    } catch (error) {}
  }

  public isAm() {
    const date = new Date();
    return date.getHours() < 12;
  }
}
