import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EntitySelectorDiagComponent } from 'src/app/common/entity-selector-diag/entity-selector-diag.component';
import { EntityService } from 'src/app/services/entity.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-team-field',
  templateUrl: './team-field.component.html',
  styleUrls: ['./team-field.component.css'],
})
export class TeamFieldComponent implements OnInit {
  @Input() ITEM: any;
  @Input() ITEMID: any;
  @Input() disabled: boolean = false;
  constructor(
    public dialog: MatDialog,
    private entityService: EntityService
  ) {}

  ngOnInit(): void {
    this.ITEM.members = [];
    if (this.ITEMID != 'new') {
      this.getMembers();
    }
  }

  public selectEntityItemDiag() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      entityCode: 'user',
    };

    const dialogRef = this.dialog.open(
      EntitySelectorDiagComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        let memberIndex = this.ITEM.members.findIndex((x) => x.id == result.id);
        if (memberIndex < 0) {
          this.ITEM.members.push(result);
        }
      }
    });
  }

  private getMembers() {
    this.entityService
      .getTeamMembers(this.ITEMID, this.ITEM)
      .pipe(take(1))
      .subscribe((res) => {
        this.ITEM.members = res;
      });
  }

  public removeMember(index: number) {
    this.ITEM.members.splice(index, 1);
  }
}
