<button
  mat-icon-button
  (click)="selectUserDiag()"
  *ngIf="!disabled"
  style="z-index: 1000"
>
  <mat-icon color="primary">person_search</mat-icon>
</button>

<div class="flexdiv">
  <div *ngIf="user" class="flexdiv">
    <span>
      <app-show-avatar [USER]="user"></app-show-avatar>
    </span>
    <span class="text-alternate-warn" style="margin-left: 15px; margin-top: 8px"
      >{{ user.firstname }} {{ user.lastname }}</span
    >
  </div>
  <div></div>
</div>
