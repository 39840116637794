import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ERPWarehouse } from 'src/app/models/erpwarehouse';
import { ErpWarehouseService } from 'src/app/services/erp/erp-warehouse.service';
import { OrgChart } from 'd3-org-chart';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-erp-admin-warehouse-tree',
  templateUrl: './erp-admin-warehouse-tree.component.html',
  styleUrls: ['./erp-admin-warehouse-tree.component.css'],
})
export class ErpAdminWarehouseTreeComponent
  implements OnInit, OnChanges, AfterViewInit
{
  public warehouseTree: ERPWarehouse;
  @Output() SelectWarehouse = new EventEmitter<any>();
  @ViewChild('chartContainer') chartContainer: ElementRef;
  chart: any;
  warehouseData: any[] = [];

  constructor(private warehouseService: ErpWarehouseService) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (!this.chart) {
      this.chart = new OrgChart();
    }
    this.updateChart();
  }

  ngOnChanges() {
    this.updateChart();
  }

  async getWarehouseTree() {
    await this.warehouseService
      .getWarehousesTree()
      .pipe(take(1))
      .subscribe((res) => {
        this.warehouseTree = res;
      });
  }

  // private getMoneyAccountTree(warehouse: ERPWarehouse, parentDepartNode: any) {
  //   let departNode = {
  //     name: warehouse.title,
  //     id:warehouse.id,
  //     warehouse:warehouse,
  //     designation: "",
  //     subordinates: []
  //   };
  //
  //   if (warehouse.sub_warehouses.length > 0) {
  //     for (let subwarehouse of warehouse.sub_warehouses) {
  //       departNode.subordinates.push(this.getMoneyAccountTree(subwarehouse, departNode));
  //     }
  //
  //   }
  //   if (parentDepartNode == null) {
  //     this.topWarehouse = departNode;
  //   }
  //
  //   return departNode;
  // }

  async updateChart() {
    if (!this.warehouseData) {
      return;
    }
    if (!this.chart) {
      return;
    }
    this.warehouseData = [];
    await this.getWarehouseTree();
    console.log('converting');
    this.getWarehouseData(this.warehouseTree, null);
    console.log(this.warehouseData);
    this.chart
      .container(this.chartContainer.nativeElement)
      .data(this.warehouseData)
      .nodeWidth((d) => 100)
      .nodeHeight((d) => 50)
      .nodeContent((node) => {
        return `<div #node style="border: darkgrey solid 1px; background-color: #efefef;
              width:${node.width}px;height:${node.height}px; text-align: center; border-radius: 5%;
              vertical-align: middle; display: table-cell">
              <strong>${node.data.title}</strong>
         </div>`;
      })
      .onNodeClick((d) => this.selectWarehouse(d.data))
      .expandAll()
      .render();
  }

  selectWarehouse(warehouse: ERPWarehouse) {
    console.log(warehouse);
    this.SelectWarehouse.emit(warehouse);
  }

  private getWarehouseData(warehouse: ERPWarehouse, parentId: number) {
    const warehouseNode = {
      id: warehouse.id,
      parentId,
      title: warehouse.title,
    };

    this.warehouseData.push(warehouseNode);

    if (warehouse.sub_warehouses.length > 0) {
      for (const subWarehouse of warehouse.sub_warehouses) {
        this.getWarehouseData(subWarehouse, warehouse.id);
      }
    }
  }
}
