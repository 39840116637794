import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TimeHelper } from 'src/app/common/helper';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.css'],
})
export class DateComponent implements OnInit {
  @Input() date: any;
  @Input() disabled = false;
  @Output() dateToString = new EventEmitter<string>();
  public selectedDate: Date;

  ngOnInit(): void {
    if (this.date) {
      this.selectedDate = new Date(this.date);
    }
  }

  public change() {
    const datePlusOne = new Date(this.selectedDate);
    this.dateToString.emit(TimeHelper.getDatetimeString(datePlusOne));
  }
}
