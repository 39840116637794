import { Component, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { CompanyService } from '../../services/company.service';

@Component({
  selector: 'app-favorites-selector',
  templateUrl: './favorites-selector.component.html',
  styleUrls: ['./favorites-selector.component.css'],
})
export class FavoritesSelectorComponent {
  @ViewChild('favoriteSelectionList') favoriteSelectionList: MatSelectionList;

  constructor(public companyService: CompanyService) {}

  selectFavorite($event: MouseEvent) {
    $event.stopPropagation();
    console.log(this.favoriteSelectionList.selectedOptions.selected);
  }
}
