<mat-card>
  <p style="margin-top: 10px">
    {{ 'ERP.doc_details' | translate }}
    <mat-divider class="itemDivider"></mat-divider>
  </p>
  <span class="docOwner">Owner</span>
  <div *ngIf="moneyDoc.owner" class="flexdiv">
    <span>
      <app-show-avatar [USER]="moneyDoc.owner"></app-show-avatar>
    </span>
    <span class="text-alternate-warn" style="margin-left: 15px; margin-top: 8px"
      >{{ moneyDoc.owner.firstname }} {{ moneyDoc.owner.lastname }}</span
    >
  </div>

  <mat-form-field class="docFld">
    <mat-label class="title">{{
      'ERPSpdoctype.doc_type' | translate
    }}</mat-label>
    <mat-select
      [(ngModel)]="moneyDoc.moneydoctype_id"
      (ngModelChange)="setTypeObj()"
    >
      <mat-option *ngFor="let type of moneydoctypes" [value]="type.id">
        {{ type.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="docFld">
    <mat-label class="title">{{ 'ERPSpdoctype.code' | translate }}</mat-label>
    <input matInput [disabled]="true" [value]="moneyDoc.code" />
  </mat-form-field>

  <div>
    <app-moneydoc-header-internal-trans
      *ngIf="moneyDoc.moneydoctype?.doc_category == 1"
      [moneyDoc]="moneyDoc"
      [moneyaccount]="moneyAccounts"
    ></app-moneydoc-header-internal-trans>
  </div>

  <div>
    <div class="docFld">
      <span *ngIf="moneyDoc.account_name"
        >{{ 'ERP.account' | translate }}: {{ moneyDoc.account_name }}</span
      >
      <span *ngIf="moneyDoc.supplier_name"
        >{{ 'ERP.supplier' | translate }}: {{ moneyDoc.supplier_name }}</span
      >
      <span *ngIf="moneyDoc.transactor_id">
        <button mat-icon-button color="warn" (click)="clearTransactor()">
          <mat-icon>clear</mat-icon>
        </button></span
      >

      <div
        *ngIf="
          moneyDoc.moneydoctype?.doc_category == 0 && !moneyDoc.transactor_id
        "
        style="z-index: 1000; background-color: white"
      >
        <app-entity-selector-autocomplete
          *ngIf="getTransactorSource() == 'supplier'"
          [ENTITY]="'supplier'"
          LABEL="{{ 'ERP.supplier' | translate }}"
          (selectEntityItem)="fillTransactorItem($event)"
          [DISABLED]="!moneyDoc.moneydoctype_id"
        >
        </app-entity-selector-autocomplete>

        <app-entity-selector-autocomplete
          *ngIf="getTransactorSource() == 'account'"
          [ENTITY]="'account'"
          LABEL="{{ 'ERP.account' | translate }}"
          (selectEntityItem)="fillTransactorItem($event)"
          [DISABLED]="!moneyDoc.moneydoctype_id"
        >
        </app-entity-selector-autocomplete>
      </div>
    </div>
    <p style="margin-top: 10px">
      {{ 'ERP.doc_generic' | translate }}
      <mat-divider class="itemDivider"></mat-divider>
    </p>

    <div style="display: flex; align-items: center">
      <mat-form-field class="docFld">
        <mat-label class="title">{{
          'ERPMoneyaccounts.moneyaccount' | translate
        }}</mat-label>
        <mat-select
          [(ngModel)]="moneyDoc.moneyaccount_id"
          (ngModelChange)="setMoneyaccountObj()"
        >
          <mat-option
            *ngFor="let moneyAccount of moneyAccounts"
            [value]="moneyAccount.id"
          >
            {{ moneyAccount.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="docFld">
        <mat-label class="title">{{
          'ERPMoneyaccounts.total' | translate
        }}</mat-label>
        <input
          matInput
          [(ngModel)]="moneyDoc.total_price"
          type="number"
          [disabled]="!moneyDoc.moneydoctype_id || !moneyDoc.moneyaccount"
        />
      </mat-form-field>

      <mat-form-field class="docFld">
        <input
          matInput
          [matDatepicker]="picker1"
          [placeholder]="'ERPMoneyaccounts.paid_date' | translate"
          [(ngModel)]="moneyDoc.paid_at"
          [disabled]="
            !(moneyDoc.moneyaccount && moneyDoc.moneyaccount.type == 2)
          "
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="docFld">
        <mat-label class="title">{{ 'ERP.remarks' | translate }}</mat-label>
        <input matInput [(ngModel)]="moneyDoc.remarks" type="string" />
      </mat-form-field>
    </div>
  </div>
</mat-card>
