import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EntitySelectorDiagComponent } from 'src/app/common/entity-selector-diag/entity-selector-diag.component';
import { SimpleAlertDiagComponent } from 'src/app/common/simple-alert-diag/simple-alert-diag.component';
import { Entdata, Entity, Field } from 'src/app/model';
import { EntityService } from 'src/app/services/entity.service';
import { LoginService } from 'src/app/services/login.service';
import { DataItemDiagComponent } from '../../../data-item-diag/data-item-diag.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-single-entity-field',
  templateUrl: './single-entity-field.component.html',
  styleUrls: ['./single-entity-field.component.css'],
})
export class SingleEntityFieldComponent implements OnInit {
  @Input() ITEM: any;
  @Input() allAttr: any;
  @Input() disabled = false;
  selectedEntityItem: Entdata;
  entityToSearch: Entity;

  constructor(
    public dialog: MatDialog,
    private entityService: EntityService,
    private translateService: TranslateService,
    private loginService: LoginService
  ) {}

  ngOnInit(): void {
    if (this.ITEM && this.ITEM.value) {
      this.getItem();
    }
    if (
      this.ITEM.for_entity === 'address' ||
      this.ITEM.for_entity === 'prodrecipe'
    ) {
      this.getFields(this.ITEM.for_entity);
    }
  }

  private getItem() {
    this.entityService
      .geEntityItem(this.ITEM.for_entity, this.ITEM.value)
      .pipe(take(1))
      .subscribe((resItem) => {
        if (this.ITEM.for_entity === 'address') {
          resItem.fields = resItem.fields.filter((x) =>
            ['address_street_address', 'address_city'].includes(
              x.field.fld_code
            )
          );
        } else {
          resItem.data = resItem.data.filter(
            (x) =>
              x.fld_code !== 'singleEntity' &&
              x.fld_code !== 'user' &&
              x.fld_code !== 'entity' &&
              x.fld_code !== 'filehistory'
          );
          resItem.fields = resItem.fields.filter(
            (x) => x.field.mobilePreview === 1
          );
        }
        this.selectedEntityItem = resItem;

        this.ITEM.singleItem = resItem;
      });
  }

  private getFields(entity: string) {
    this.entityService
      .getFldsEntityByName(entity)
      .pipe(take(1))
      .subscribe((resFlds) => {
        this.entityToSearch = resFlds;
      });
  }

  public selectEntityItemDiag() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      entityCode: this.ITEM.for_entity,
      searchFields: this.getPredefinedSearchFlds(),
    };

    const dialogRef = this.dialog.open(
      EntitySelectorDiagComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        if (this.ITEM.fld_code_ent === 'task_childtask') {
          if (this.isPrerequisiteTaskOlder(result)) {
            this.ITEM.value = result.id;
            this.getItem();
          }
        } else {
          this.ITEM.value = result.id;
          this.getItem();
        }
      }
    });
  }

  private getPredefinedSearchFlds(): Field[] {
    let searchFields: Field[];
    let value = null;
    let searchFieldId = 0;

    if (this.ITEM.for_entity === 'address') {
      const entityPrefix = this.ITEM.fld_code_ent.split('_')[0];

      const indexSparePartLead = this.allAttr.findIndex(
        (x) => x.fld_code_ent === 'spare_part_lead'
      );
      const indexSparePartAccount = this.allAttr.findIndex(
        (x) => x.fld_code_ent === 'spare_part_account'
      );
      const indexMaintenanceFileLead = this.allAttr.findIndex(
        (x) => x.fld_code_ent === 'maintenance_file_lead'
      );
      const indexMaintenanceFileAccount = this.allAttr.findIndex(
        (x) => x.fld_code_ent === 'maintenance_file_account'
      );
      const indexEntityLead = this.allAttr.findIndex(
        (x) => x.fld_code_ent === `${entityPrefix}_lead`
      );
      const indexEntityAccount = this.allAttr.findIndex(
        (x) => x.fld_code_ent === `${entityPrefix}_account`
      );

      if (
        indexSparePartLead !== -1 &&
        this.allAttr[indexSparePartLead].value != null
      ) {
        value = this.allAttr[indexSparePartLead].value;
        searchFieldId =
          this.entityToSearch.fields.filter(
            (x) => x.fld_code === 'address_lead'
          )[0]?.id ?? 0;
      } else if (
        indexSparePartAccount !== -1 &&
        this.allAttr[indexSparePartAccount].value != null
      ) {
        value = this.allAttr[indexSparePartAccount].value;
        searchFieldId =
          this.entityToSearch.fields.filter(
            (x) => x.fld_code === 'address_customer'
          )[0]?.id ?? 0;
      } else if (
        indexMaintenanceFileLead !== -1 &&
        this.allAttr[indexMaintenanceFileLead].value != null
      ) {
        value = this.allAttr[indexMaintenanceFileLead].value;
        searchFieldId =
          this.entityToSearch.fields.filter(
            (x) => x.fld_code === 'address_lead'
          )[0]?.id ?? 0;
      } else if (
        indexMaintenanceFileAccount !== -1 &&
        this.allAttr[indexMaintenanceFileAccount].value != null
      ) {
        value = this.allAttr[indexMaintenanceFileAccount].value;
        searchFieldId =
          this.entityToSearch.fields.filter(
            (x) => x.fld_code === 'address_customer'
          )[0]?.id ?? 0;
      } else if (
        indexEntityLead !== -1 &&
        this.allAttr[indexEntityLead].value != null
      ) {
        value = this.allAttr[indexEntityLead].value;
        searchFieldId =
          this.entityToSearch.fields.filter(
            (x) => x.fld_code === 'address_lead'
          )[0]?.id ?? 0;
      } else if (
        indexEntityAccount !== -1 &&
        this.allAttr[indexEntityAccount].value != null
      ) {
        value = this.allAttr[indexEntityAccount].value;
        searchFieldId =
          this.entityToSearch.fields.filter(
            (x) => x.fld_code === 'address_customer'
          )[0]?.id ?? 0;
      } else {
        return searchFields;
      }

      searchFields = [];
      const accountSearchField: Field = new Field();
      accountSearchField.search_from = value;
      accountSearchField.id = searchFieldId;
      accountSearchField.fld_code = 'singleEntity';
      searchFields.push(accountSearchField);
    } else if (this.ITEM.for_entity === 'prodrecipe') {
      const indexProductionorderProduct = this.allAttr.findIndex(
        (x) => x.fld_code_ent === 'productionorder_product'
      );

      if (
        indexProductionorderProduct !== -1 &&
        this.allAttr[indexProductionorderProduct].value != null
      ) {
        value = this.allAttr[indexProductionorderProduct].value;
        searchFieldId =
          this.entityToSearch.fields.filter(
            (x) => x.fld_code === 'prodrecipe_product'
          )[0]?.id ?? 0;
      } else {
        return searchFields;
      }

      searchFields = [];
      const accountSearchField: Field = new Field();
      accountSearchField.search_from = value;
      accountSearchField.id = searchFieldId;
      searchFields.push(accountSearchField);
    }

    return searchFields;
  }

  public openItem() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      itemId: this.ITEM.value,
      entityCode: this.ITEM.for_entity,
    };
    this.dialog.open(DataItemDiagComponent, dialogConfig);
  }

  clearValue() {
    this.ITEM.value = null;
  }

  private isPrerequisiteTaskOlder(selectedTask: any): boolean {
    const parentDate = new Date(selectedTask.task_startdate);

    const current = this.allAttr.find(
      (x) => x.fld_code_ent === 'task_startdate'
    );
    const currentStartdate = new Date(current.value);

    const flag = currentStartdate > parentDate;

    if (!flag) {
      const alertdialogConfig = new MatDialogConfig();
      alertdialogConfig.data = {
        title: this.translateService.instant('Generic.error'),
        message: this.translateService.instant('Messages.parentDateIsBigger'),
      };
      this.dialog.open(SimpleAlertDiagComponent, alertdialogConfig);
    }
    return currentStartdate > parentDate;
  }
}
