import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Module } from 'src/app/model';
import { CompanyService } from 'src/app/services/company.service';
import { LoginService } from 'src/app/services/login.service';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-permition-bulk-assign',
  templateUrl: './permition-bulk-assign.component.html',
  styleUrls: ['./permition-bulk-assign.component.css'],
})
export class PermitionBulkAssignComponent implements OnInit {
  modules: Module[] = [];
  selectedModules: Module[] = [];
  destroyRef = inject(DestroyRef);

  constructor(
    private companyService: CompanyService,
    private loginService: LoginService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.getModules();
  }

  private getModules() {
    this.companyService.teamworkModules
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((resModule) => {
        this.modules = resModule;
      });
  }

  public getTraslsatedModuleName(module: Module): string {
    return this.loginService.getLoginUser().lang == 'en'
      ? module.name
      : module.name_gr;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));
}
