import { inject, Pipe, PipeTransform } from '@angular/core';
import { QuestionEntfield } from '../model';
import { LoginService } from '../services/login.service';

@Pipe({
  name: 'workflowStepQuestionEntfield',
})
export class WorkflowStepQuestionEntfieldPipe implements PipeTransform {
  private loginService = inject(LoginService);
  transform(value: QuestionEntfield): string {
    const activeLanguage = this.loginService.getLoginUser().lang;
    return activeLanguage === 'en'
      ? `#${value.id} - ${value.labelEn} of entity ${value.entity.nameEn}`
      : `#${value.id} - ${value.labelGr} της οντότητας ${value.entity.nameGr}`;
  }
}
