import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApprovalFilter } from '../common/filters';
import { PagedData, ReturnData } from '../model';
import { Approval, Assignee } from '../models/approval';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApprovalService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getUserApprovalsForSign(
    filters: ApprovalFilter
  ): Observable<PagedData<Approval>> {
    return this.post<PagedData<Approval>>(
      this.apiUrl + `approvals/forsign?page=${filters.currentPage}`,
      filters
    );
  }
  public getUserApprovalsRequests(
    filters: ApprovalFilter
  ): Observable<PagedData<Approval>> {
    return this.post<PagedData<Approval>>(
      this.apiUrl + `approvals/requests?page=${filters.currentPage}`,
      filters
    );
  }

  public getSingleApproval(approvalId: string): Observable<Approval> {
    return this.get<Approval>(this.apiUrl + 'approval/' + approvalId);
  }

  public addApproval(approval: Approval): Observable<ReturnData<Approval>> {
    return this.post<ReturnData<Approval>>(this.apiUrl + 'approval', approval);
  }

  public approve(assignee: Assignee, approvalId): Observable<Approval> {
    return this.post<Approval>(
      this.apiUrl + 'approval/approve/' + approvalId,
      assignee
    );
  }

  public reject(assignee: Assignee, approvalId): Observable<Approval> {
    return this.post<Approval>(
      this.apiUrl + 'approval/reject/' + approvalId,
      assignee
    );
  }

  public approveAndFrw(
    assignee: Assignee,
    approvalId,
    userId
  ): Observable<Approval> {
    return this.post<Approval>(
      this.apiUrl + 'approval/approveFrw/' + approvalId + '/' + userId,
      assignee
    );
  }

  public getApprovalTree(approvalId: string): Observable<Approval[]> {
    return this.get<Approval[]>(this.apiUrl + 'approval/tree/' + approvalId);
  }

  public getApprovalsForItem(itemId: string): Observable<Approval[]> {
    return this.get<Approval[]>(this.apiUrl + 'approvals/item/' + itemId);
  }
}
