import { Component, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EntityMenuSelectorComponent } from 'src/app/common/entity-menu-selector/entity-menu-selector.component';
import { ConnectedEntity, Entconnection, Entdata, Entity } from 'src/app/model';
import { EntityService } from 'src/app/services/entity.service';
import { LoginService } from 'src/app/services/login.service';
import { DataItemDiagComponent } from '../../data-item-diag/data-item-diag.component';
import { EntitySelectorDiagMultipleComponent } from '../../../entity-selector-diag-multiple/entity-selector-diag-multiple.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-data-connected-list',
  templateUrl: './data-connected-list.component.html',
  styleUrls: ['./data-connected-list.component.css'],
})
export class DataConnectedListComponent {
  @Input() ITEMID: any;
  @Input() currentEntItem: Entdata;
  avConnectedEntities: ConnectedEntity[] = [];

  @ViewChild('addlinkMenu') entityMenuSelectorLink: EntityMenuSelectorComponent;
  @ViewChild('addNewItemMenu')
  entityMenuSelectorNewItem: EntityMenuSelectorComponent;

  constructor(
    private entityService: EntityService,
    private loginService: LoginService,
    public dialog: MatDialog,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnChanges(): void {
    if (this.ITEMID != 'new') {
      this.getEntCodeConnections();
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  public getEntCodeConnections() {
    this.entityService
      .getEntConnection(this.ITEMID)
      .pipe(take(1))
      .subscribe((resCon) => {
        this.avConnectedEntities = resCon as ConnectedEntity[];
      });
  }

  public getEntityNameTranslate(entity: Entity): string {
    let lang = this.loginService.getLoginUser().lang;

    return lang == 'en' ? entity.name_en : entity.name_gr;
  }

  public quickCreateItem(entity_code: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      itemId: 'new',
      entityCode: entity_code,
      sourceItemId: this.ITEMID,
    };
    const dialogRef = this.dialog.open(DataItemDiagComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getEntCodeConnections();
      }
    });
  }

  public selectEntityIDiag(entity_code: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      entityCode: entity_code,
    };

    const dialogRef = this.dialog.open(
      EntitySelectorDiagMultipleComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result: number[]) => {
      if (result.length !== 0) {
        this.newConnection(result);
      }
    });
  }

  private newConnection(entityItemIds: number[]) {
    let newConnection: Entconnection = new Entconnection();
    newConnection.entdata_id = +this.currentEntItem.id;
    newConnection.with_entdata_ids = entityItemIds;
    this.entityService
      .addEntConnection(newConnection)
      .pipe(take(1))
      .subscribe((resCon) => {
        if (resCon.success) {
          this.avConnectedEntities = resCon.data;
          this.entityService.selectedItemIds.next([]);
        }
      });
  }
}
