import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/model';
import { UntypedFormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { NotificationService } from 'src/app/services/notification.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-change-password-diag',
  templateUrl: './change-password-diag.component.html',
  styleUrls: ['./change-password-diag.component.css'],
})
export class ChangePasswordDiagComponent implements OnInit {
  hidePass = true;
  hideCPass = true;
  user: User = new User();
  passMutch: boolean = true;
  constructor(
    private dialogRef: MatDialogRef<ChangePasswordDiagComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private notificationService: NotificationService
  ) {}

  passwordFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(
      '^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\\d\\x])(?=.*[!$#%]).*$'
    ),
    Validators.minLength(8),
  ]);
  cpasswordFormControl = new UntypedFormControl('', [Validators.required]);

  ngOnInit(): void {
    this.user = this.data.user;
  }

  changePass() {
    if (!this.passwordFormControl.hasError('required')) {
      this.userService
        .changePass(this.user)
        .pipe(take(1))
        .subscribe((res) => {
          if (res.success) {
            this.notificationService.showSnackbarMessage(
              'Messages.successfulSave'
            );
            this.user.password = '';
            this.user.cpassword = '';
          } else {
            this.notificationService.showSnackbarMessage('Messages.failSave');
            this.user.password = '';
            this.user.cpassword = '';
          }
        });
    }
  }
}
