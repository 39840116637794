import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReturnData } from '../model';
import { PurchasingorderLine } from '../models/purchasingorder';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PurchasingorderService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getSavedLines(
    purchasingorderId: string
  ): Observable<PurchasingorderLine[]> {
    return this.get<PurchasingorderLine[]>(
      this.apiUrl + 'purchasingorder/' + purchasingorderId
    );
  }

  public splitBySupplier(
    purchasingorderId: string
  ): Observable<ReturnData<any>> {
    return this.get<ReturnData<any>>(
      this.apiUrl + 'purchasingorder/splitbysupplier/' + purchasingorderId
    );
  }
}
