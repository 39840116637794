import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  NgxScannerQrcodeComponent,
  ScannerQRCodeResult,
} from 'ngx-scanner-qrcode';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-qrcode-scanner',
  templateUrl: './qrcode-scanner.component.html',
  styleUrls: ['./qrcode-scanner.component.css'],
})
export class QrcodeScannerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('scanner') scanner: NgxScannerQrcodeComponent;
  cameraDevices: any[] = [];
  activeCameraId: string;

  constructor(
    public dialogRef: MatDialogRef<QrcodeScannerComponent>,
    private destroyRef: DestroyRef
  ) {}

  ngAfterViewInit() {
    this.scanner.start();
    this.setDefaultCamera();
  }

  selectQRCode($event: ScannerQRCodeResult[]) {
    this.scanner.stop();
    this.dialogRef.close($event[0].value);
  }

  ngOnDestroy() {
    this.scanner.stop();
  }

  changeCamera() {
    this.activeCameraId = this.cameraDevices.filter(
      (camera) => camera.deviceId !== this.activeCameraId
    )[0].deviceId;
    this.scanner.playDevice(this.activeCameraId);
    localStorage.setItem('activeCamera', this.activeCameraId);
  }

  private setDefaultCamera() {
    this.scanner.devices
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((devices) => {
        if (devices.length > 0) {
          this.cameraDevices = devices;
          // Get saved camera id form local storage
          const activeCamera = localStorage.getItem('activeCamera');
          if (!activeCamera) {
            // Make the back camera default if it exists
            const backCamera = devices.find((f) =>
              /back|rear|environment|πίσω/gi.test(f.label)
            );
            this.activeCameraId = backCamera
              ? backCamera.deviceId
              : devices[0].deviceId;
          } else {
            this.activeCameraId = activeCamera;
          }
          this.scanner.playDevice(this.activeCameraId);
          // Code for Apple devices
          setTimeout(() => {
            this.scanner.playDevice(this.activeCameraId);
          }, 1000);
        }
      });
  }
}
