<h1 mat-dialog-title>{{ 'LocalAdmin.changePass' | translate }}</h1>
<div mat-dialog-content>
  <mat-list class="change-pass-list">
    <mat-list-item role="listitem">
      <mat-form-field class="input-width">
        <mat-label>{{ 'Register.password' | translate }}</mat-label>
        <input
          matInput
          [type]="hidePass ? 'password' : 'text'"
          [(ngModel)]="user.password"
          [formControl]="passwordFormControl"
        />

        <button
          mat-icon-button
          matSuffix
          (click)="hidePass = !hidePass"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hidePass"
        >
          <mat-icon>{{ hidePass ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
      </mat-form-field>
    </mat-list-item>
    <mat-list-item role="listitem">
      <mat-form-field class="input-width">
        <mat-label>{{ 'Register.cpassword' | translate }}</mat-label>
        <input
          matInput
          [type]="hideCPass ? 'password' : 'text'"
          [(ngModel)]="user.cpassword"
          [formControl]="cpasswordFormControl"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="hideCPass = !hideCPass"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hideCPass"
        >
          <mat-icon>{{ hideCPass ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
      </mat-form-field>
    </mat-list-item>
  </mat-list>
  <div>
    <mat-error
      class="errorArea"
      *ngIf="
        this.passwordFormControl.hasError('pattern') ||
        this.passwordFormControl.hasError('minlength')
      "
      [innerHTML]="'Messages.passwordComplexityError' | translate"
    >
    </mat-error>
  </div>
  <div>
    <mat-error class="errorArea" *ngIf="user.password != user.cpassword">
      {{ 'Messages.passwordNotMuch' | translate }}
    </mat-error>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="null">
    {{ 'Generic.cancel' | translate }}
  </button>
  <button
    mat-button
    [mat-dialog-close]="null"
    (click)="changePass()"
    [disabled]="
      !user.password ||
      user.password == '' ||
      user.password != user.cpassword ||
      this.passwordFormControl.hasError('pattern') ||
      this.passwordFormControl.hasError('minlength')
    "
  >
    {{ 'Generic.save' | translate }}
  </button>
</div>
