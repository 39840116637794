<button
  mat-icon-button
  (click)="selectEntityItemDiag()"
  style="margin-left: 10px; z-index: 1000"
  *ngIf="!disabled"
>
  <mat-icon color="primary">search</mat-icon>
</button>

<div class="flexdiv">
  <div>
    <table *ngIf="ITEM.value">
      <tr class="flexClass">
        <td style="font-weight: bold">#{{ ITEM.value }}</td>
        <td *ngFor="let attr of selectedEntityItem?.fields">
          <span class="attrSpan">{{ attr.value }}</span>
        </td>
        <td>
          <button
            mat-icon-button
            color="warn"
            style="margin-top: -20%"
            *ngIf="!disabled && ITEM.value"
            (click)="clearValue()"
          >
            <mat-icon>clear</mat-icon>
          </button>

          <button
            mat-icon-button
            color="primary"
            style="margin-top: -20%"
            *ngIf="ITEM.value"
            (click)="openItem()"
          >
            <mat-icon>visibility</mat-icon>
          </button>
        </td>
      </tr>
    </table>
  </div>
</div>
