import { Component, inject, Input } from '@angular/core';
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerToggle,
} from '@angular/material/datepicker';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { DatePipe } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import { enGB } from 'date-fns/locale';
import { MY_FORMATS } from '../../../data-item/fields/month-picker/month-picker.component';

@Component({
  selector: 'app-month-filter',
  standalone: true,
  providers: [
    {
      provide: DateAdapter,
      useClass: DateFnsAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: enGB },
    DatePipe,
  ],
  imports: [
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatInput,
    MatSuffix,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
  ],
  templateUrl: './month-filter.component.html',
  styleUrl: './month-filter.component.css',
})
export class MonthFilterComponent {
  @Input() ITEM!: any;
  private readonly datePipe = inject(DatePipe);

  date = new FormControl();

  setMonthAndYear(selectedDate: Date, datepicker: MatDatepicker<Date>) {
    this.date.setValue(selectedDate);
    this.ITEM.search_from = this.datePipe.transform(selectedDate, 'yyyy-MM');
    datepicker.close();
  }

  clearValue() {
    this.date.setValue(null);
  }

  setValue(value: string) {
    this.date.setValue(value);
  }
}
