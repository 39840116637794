import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import {
  ReturnData,
  Invitation,
  User,
  UserJoinDepartment,
  Module,
  PagedData,
  ChatUser,
  Avatar,
  userSearchFilterPref,
} from '../model';
import { BaseFilter, UsersFilter } from '../common/filters';
import { SpinnerType } from '../common/enums';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  public CASHEDAVATARS: Avatar[] = [];
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public validateRegisterToken(
    token: string
  ): Observable<ReturnData<Invitation>> {
    let formData: FormData = new FormData();
    formData.append('token', token);
    return this.post<ReturnData<Invitation>>(
      this.apiUrl + 'invitation',
      formData
    );
  }

  public newUserFromInvitation(
    token: string,
    user: User
  ): Observable<ReturnData<User>> {
    return this.post<ReturnData<User>>(
      this.apiUrl + 'invitation/' + token,
      user
    );
  }

  public getUsers(
    filters: BaseFilter
  ): Observable<PagedData<UserJoinDepartment>> {
    return this.post<PagedData<UserJoinDepartment>>(
      this.apiUrl + 'users?page=' + filters.currentPage,
      filters
    );
  }
  public getUsersWithUnreadChat(
    filters: UsersFilter
  ): Observable<PagedData<ChatUser>> {
    return this.post<PagedData<ChatUser>>(
      this.apiUrl + 'chat/users?page=' + filters.currentPage,
      filters
    );
  }
  public getUser(userId: string): Observable<User> {
    return this.get<User>(this.apiUrl + 'admin/users/' + userId);
  }
  public getUserCommon(
    userId: string,
    spinnerType: SpinnerType = SpinnerType.Big
  ): Observable<User> {
    return this.get<User>(this.apiUrl + 'user/' + userId, spinnerType);
  }
  public updateUser(
    user: User,
    modules: Module[]
  ): Observable<ReturnData<User>> {
    let obj = {
      user: user,
      modules: modules,
    };
    return this.put<ReturnData<User>>(this.apiUrl + 'admin/user', obj);
  }

  public bulkUpdatePermissions(
    users: User[],
    modules: Module[]
  ): Observable<ReturnData<User>> {
    let obj = {
      users: users,
      modules: modules,
    };
    return this.post<ReturnData<User>>(
      this.apiUrl + 'admin/bulkpermissions',
      obj
    );
  }

  public updateProfile(user: User): Observable<ReturnData<User>> {
    return this.put<ReturnData<User>>(this.apiUrl + 'user', user);
  }

  public addUser(user: User, modules: Module[]): Observable<ReturnData<User>> {
    let obj = {
      user: user,
      modules: modules,
    };
    return this.post<ReturnData<User>>(this.apiUrl + 'admin/user', obj);
  }

  public deleteUser(user: User): Observable<ReturnData<any>> {
    return this.delete<ReturnData<any>>(this.apiUrl + 'admin/user/' + user.id);
  }

  public restoreUser(user: User): Observable<ReturnData<User>> {
    return this.get<ReturnData<User>>(
      this.apiUrl + 'admin/users/' + user.id + '/restore'
    );
  }

  public changeAvatar(userId: number, formData: FormData): Observable<any> {
    return this.post<any>(this.apiUrl + 'user/' + userId + '/avatar', formData);
  }

  public getAvatar(userId: number): Observable<any> {
    return this.get<any>(
      this.apiUrl + 'user/' + userId + '/avatar',
      SpinnerType.None
    );
  }

  public changePass(user: User): Observable<ReturnData<any>> {
    return this.post<ReturnData<any>>(this.apiUrl + 'user/changepass', user);
  }

  public subscribeNotifications(token: string): Observable<ReturnData<any>> {
    let obj = { token: token };
    return this.post<ReturnData<any>>(
      this.apiUrl + 'user/pushnotification',
      obj
    );
  }

  public cacheAvatar(user_id: number, avatarImg: string) {
    let index = this.CASHEDAVATARS.findIndex((x) => x.user_id == user_id);
    if (index == -1 && avatarImg != '') {
      let avatar: Avatar = new Avatar();
      avatar.user_id = user_id;
      avatar.image = avatarImg;
      this.CASHEDAVATARS.push(avatar);
    }
  }

  public getAvatarFormCache(user_id: number) {
    let index = this.CASHEDAVATARS.findIndex((x) => x.user_id == user_id);
    return index != -1 ? this.CASHEDAVATARS[index].image : null;
  }

  public getUserSearchFilterPrefs(): Observable<userSearchFilterPref[]> {
    return this.get<userSearchFilterPref[]>(this.apiUrl + 'user/searchprefs');
  }
  // public getUserSearchFilterPrefs(): Promise<ReturnData<any>> {
  //   return this.get<ReturnData<any>>(this.apiUrl + 'user/searchprefs');
  // }

  public addUserSearchFilterPref(
    entfield_id: number
  ): Observable<ReturnData<any>> {
    let obj = {
      entfield_id: entfield_id,
    };
    return this.post<ReturnData<any>>(
      this.apiUrl + 'user/searchprefs/add',
      obj
    );
  }

  public deleteUserSearchFilterPref(
    entfield_id: number
  ): Observable<ReturnData<any>> {
    return this.delete<ReturnData<any>>(
      this.apiUrl + 'user/searchprefs/delete/' + entfield_id
    );
  }
}
