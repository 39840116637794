import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BulkEntdata, Question, User } from 'src/app/model';
import { EntityService } from 'src/app/services/entity.service';
import { LoginService } from 'src/app/services/login.service';
import { NotificationService } from 'src/app/services/notification.service';
import { QuestionService } from 'src/app/services/question.service';
import { EntitySelectorDiagComponent } from '../../entity-selector-diag/entity-selector-diag.component';

@Component({
  selector: 'app-data-question',
  templateUrl: './data-question.component.html',
  styleUrls: ['./data-question.component.css'],
})
export class DataQuestionComponent implements OnInit {
  public entityCode: string;
  public itemId: string;
  public currentEntItem: BulkEntdata;
  public receiver: User;
  public message: string = '';

  constructor(
    public dialogRef: MatDialogRef<DataQuestionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private breakpointObserver: BreakpointObserver,
    private entityService: EntityService,
    public dialog: MatDialog,
    public loginService: LoginService,
    private questionService: QuestionService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.entityCode = this.data.entityCode;
    this.itemId = this.data.itemId;
    this.getEntityItem();
  }

  private getEntityItem() {
    this.entityService
      .geEntityItem(this.entityCode, this.itemId)
      .pipe(take(1))
      .subscribe((resItem) => {
        this.currentEntItem = resItem as BulkEntdata;
        console.log(this.currentEntItem);
      });
  }

  public selectUserDiag() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      entityCode: 'user',
      // entity_id: this.entity.id
    };

    const dialogRef = this.dialog.open(
      EntitySelectorDiagComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        this.receiver = result;
      }
    });
  }

  public save() {
    let selectedFlds = this.getSelectedAttrs();
    let newQuestions: Question[] = [];
    for (let selectedFld of selectedFlds) {
      let newQuestion: Question = new Question();
      newQuestion.message = this.message;
      newQuestion.entdata_id = +this.itemId;
      newQuestion.to_id = this.receiver.id;
      newQuestion.entfield_id = selectedFld.id;
      newQuestions.push(newQuestion);
    }
    this.questionService
      .postQuestions(newQuestions)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.success) {
          this.notificationService.showSnackbarMessage(
            'Messages.successfulSave'
          );
          this.dialogRef.close();
        } else {
          this.notificationService.showSnackbarMessage('Messages.failSave');
        }
      });
  }

  public getOnlyQuestionableAttrs(): any[] {
    return this.currentEntItem?.data.filter(
      (x) =>
        x.fld_code != 'label' && x.fld_code != 'tab' && x.fld_code != 'hidden'
    );
  }

  public getSelectedAttrs(): any[] {
    return this.currentEntItem.data.filter((x) => x.changeAttr);
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));
}
