<mat-form-field class="full-width">
  <mat-label class="title">{{ title }}</mat-label>
  <mat-select
    [(ngModel)]="chosenCostCenter"
    (ngModelChange)="change()"
    class="full-width"
  >
    <mat-option *ngFor="let option of costCenters" [value]="option.id">
      {{ option.title }}
    </mat-option>
  </mat-select>
</mat-form-field>
