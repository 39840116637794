import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { EntityService } from '../../services/entity.service';
import { ConfQuoteLine, ConfQuote, Field, Entdata } from '../../model';
import { EntityItemFilter } from '../filters';
import { map, switchMap, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { SettingsService } from 'src/app/services/settings.service';
import { ErpWarehouseService } from 'src/app/services/erp/erp-warehouse.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-child-products-dialog',
  templateUrl: './child-products-dialog.component.html',
  styleUrls: ['./child-products-dialog.component.css'],
})
export class ChildProductsDialogComponent implements OnInit {
  dialogData: {
    colors: string[];
    sizes: string[];
    tableData: { [color: string]: { [size: string]: { qty: number; stock: number } } };
    children: any[];
    validCombinations: Set<string>;
  } = {
    colors: [],
    sizes: [],
    tableData: {},
    children: [],
    validCombinations: new Set<string>(),
  };
  tableData: { [color: string]: { [size: string]: number } } = {};
  displayedPopColumns: string[] = [];
  confQuote: ConfQuote;
  addressItem: any;
  addressId: string;
  allAttr: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChildProductsDialogComponent>,
    private entityService: EntityService,
    private settingsService: SettingsService,
    private erpWarehouseService: ErpWarehouseService
  ) {
    this.confQuote = data?.confQuote;
    this.allAttr = data?.allAttr || [];
  }

  ngOnInit(): void {
    if (this.data?.parentId) {
      this.loadChildren(this.data.parentId);
    }

    let addressAttr = this.allAttr.find(
      (x) => x.fld_code == 'singleEntity' && x.for_entity == 'address'
    );
    this.addressId = addressAttr?.value;
    if (addressAttr && addressAttr.value) {
      this.getAddressItem(addressAttr.value);
    }
  }

  ngDoCheck(): void {
    let tmpaddressAttr = this.allAttr.find(
      (x) => x.fld_code == 'singleEntity' && x.for_entity == 'address'
    )?.value;

    if (this.addressId != tmpaddressAttr && tmpaddressAttr) {
      this.addressId = tmpaddressAttr;
      this.getAddressItem(this.addressId);
    }
  }

  private loadChildren(parentId: number): void {
    this.getChildrenProducts(parentId)
      .pipe(take(1))
      .subscribe((children) => {
        if (children.length > 0) {
          this.prepareDialogData(children);
        }
      });
  }

  private prepareDialogData(children: any[]): void {
    const colors = new Set<string>();
    const sizes = new Set<string>();
    const validCombinations = new Set<string>();
    const tableData: { [color: string]: { [size: string]: { qty: number; stock: number } } } = {};

    this.settingsService.getMrpErpSettings('mrp_erp_stock')
        .pipe(
            map((settings) => {
                return settings.some((setting) => setting.value === 'true');
            }),
            switchMap((isMrpErpStockEnabled) => {
                const stockFetchObservables = children.map((child) => {
                    const productCodeField = child.fields.find(
                        (field: any) => field.fieldCode === 'product_code'
                    );
                    if (!productCodeField || !productCodeField.value) return null;

                    const productStockField = child.fields.find(
                        (field: any) => field.fieldCode === 'product_stock'
                    );
                    const productCode = productCodeField.value;
                    const parts = productCode.split('/');
                    if (parts.length < 3) return null;

                    const color = parts[parts.length - 2];
                    const size = parts[parts.length - 1];

                    colors.add(color);
                    sizes.add(size);
                    validCombinations.add(`${color}/${size}`);

                    if (!tableData[color]) {
                        tableData[color] = {};
                    }

                    if (isMrpErpStockEnabled) {
                        return this.erpWarehouseService.getProductWhStocks(child.id).pipe(
                            map((stocks) => {
                                const totalStock = stocks.reduce(
                                    (sum, warehouseStock) => sum + warehouseStock.stock,
                                    0
                                );
                                tableData[color][size] = {
                                    qty: 0,
                                    stock: totalStock,
                                };
                            })
                        );
                    } else {
                        const stock = productStockField?.value || 0;
                        tableData[color][size] = {
                            qty: 0,
                            stock,
                        };
                        return of(null);
                    }
                });
                const validObservables = stockFetchObservables.filter((obs) => obs !== null);
                return forkJoin(validObservables);
            })
        )
        .subscribe({
            next: () => {
                this.dialogData = {
                    colors: Array.from(colors),
                    sizes: Array.from(sizes).sort((a, b) => +a - +b),
                    tableData,
                    children,
                    validCombinations,
                };

                this.displayedPopColumns = ['color', ...this.dialogData.sizes];
            }
        });
}

  

  private processSelectedItems(dialogData: any): void {
    const linesToAdd: ConfQuoteLine[] = [];
    const currentMaxAA =
      this.confQuote.quotelines.length > 0
        ? Math.max(...this.confQuote.quotelines.map((line) => line.aa - 1 || 0))
        : 0;

    let nextAA = currentMaxAA + 1;

    const observables: Observable<Entdata>[] = [];
    const childProductDetails: [number, number][] = [];

    Object.keys(dialogData.tableData).forEach((color) => {
      Object.keys(dialogData.tableData[color]).forEach((size) => {
        const qty = dialogData.tableData[color][size];

        if (qty > 0) {
          const productCode = `${color}/${size}`;
          const productEntry = dialogData.children.find((child) =>
            child.fields.some(
              (field: any) =>
                field.fieldCode === 'product_code' &&
                field.value.endsWith(`/${productCode}`)
            )
          );

          if (productEntry) {
            observables.push(
              this.entityService.geEntityItem('product', productEntry.id)
            );
            childProductDetails.push([qty, nextAA++]);
          }
        }
      });
    });

    forkJoin(observables)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          for (let i = 0; i < result.length; i++) {
            linesToAdd.push(
              this.createNewLine(
                result[i],
                childProductDetails[i][0],
                childProductDetails[i][1]
              )
            );
          }
          this.dialogRef.close(linesToAdd);
        }
      });
  }

  private createNewLine(product: any, qty: number, aa: number): ConfQuoteLine {
    if (!product || !product.fields) {
      throw new Error('Product object is invalid or missing fields.');
    }

    const productPriceField = product.fields.find(
      (field: any) => field.field.fld_code === 'product_price_per_piece'
    );

    const productNameField = product.fields.find(
      (field: any) => field.field.fld_code === 'product_name'
    );
    const productCodeField = product.fields.find(
      (field: any) => field.field.fld_code === 'product_code'
    );

    const productPricePerPiece = productPriceField?.value || 0;

    const newLine = new ConfQuoteLine();
    newLine.product = {
      id: product.id,
      product_name: productNameField?.value,
      product_code: productCodeField?.value,
    };
    newLine.product_id = product.id;
    newLine.qty = qty;
    newLine.price = +productPricePerPiece;
    newLine.availability = 1;
    newLine.numFld1 = 0;
    newLine.discount = this.data?.totalDiscount ?? 0;
    newLine.discount2 = 0;
    newLine.unit = 0;
    newLine.aa = aa;

    let kathestosPerioxis = this.addressItem?.data?.find(
      (x) => x.fld_code_ent == 'address_fpa'
    )?.value;

    const vatLookupTable = {
      'Κανονικός 24% + Κανονικό': 24,
      'Κανονικός 24% + Μειωμένο (-30%)': 17,
      'Κανονικός 24% + Χωρίς ΦΠΑ': 0,
      'Μειωμένος 13% + Κανονικό': 13,
      'Μειωμένος 13% + Μειωμένο (-30%)': 9,
      'Μειωμένος 13% + Χωρίς ΦΠΑ': 0,
      'Υπερμειωμένος 6% + Κανονικό': 6,
      'Υπερμειωμένος 6% + Μειωμένο (-30%)': 4,
      'Υπερμειωμένος 6% + Χωρίς ΦΠΑ': 0,
    };

    const combinedKey = product.product_vat + ' + ' + kathestosPerioxis;
    newLine.vat = vatLookupTable[combinedKey] ?? 24;

    return newLine;
  }

  private getChildrenProducts(parentId: number): Observable<any[]> {
    return this.entityService.getFldsEntityByNameByCompany('product', 1).pipe(
      take(1),
      switchMap((response) => {
        const fields = response.fields;
        const productChildOfField = fields.find(
          (field: any) => field.fld_code === 'product_child_of'
        );
        if (!productChildOfField) {
          throw new Error(
            'Field "product_child_of" not found in entity "product".'
          );
        }

        const filter: EntityItemFilter = new EntityItemFilter();
        filter.pageSize = 500;
        filter.searchFields = [
          {
            id: productChildOfField.id,
            fld_code: 'product_child_of',
            search_from: String(parentId),
          } as Field,
        ];
        return this.entityService.getEntityDataList(filter, 'product');
      }),
      map((response) => {
        return response.data.filter((child) => child.id !== parentId);
      })
    );
  }

  confirmSelection(dialogData: any): void {
    this.processSelectedItems(dialogData);
  }

  static openChildProductsDialog(
    dialog: MatDialog,
    parentId: number,
    currentMaxAA: number,
    totalDiscount: number,
    confQuote: ConfQuote,
    callback: (linesToAdd: ConfQuoteLine[]) => void,
    allAttr: any
  ): void {
    const dialogRef = dialog.open(ChildProductsDialogComponent, {
      width: '60%',
      data: {
        parentId,
        currentMaxAA,
        totalDiscount,
        confQuote,
        allAttr, // Pass `allAttr` here
      },
    });

    dialogRef.afterClosed().subscribe((linesToAdd: ConfQuoteLine[]) => {
      if (linesToAdd) {
        callback(linesToAdd);
      }
    });
  }

  private getAddressItem(addressId: string) {
    this.entityService
      .geEntityItem('address', addressId)
      .pipe(take(1))
      .subscribe((resItem) => {
        this.addressItem = resItem;
      });
  }

  
}
