<div style="height: fit-content" #listContainer>
  <div>
    <mat-list class="postlist" [scrollTop]="scrollPosition">
      <mat-list-item
        *ngFor="let post of posts"
        style="height: fit-content; padding: 0px"
      >
        <app-show-avatar matListItemIcon [USER]="post.user"></app-show-avatar>
        <div matListItemTitle>
          <span style="font-size: 10px; color: grey; margin-top: 18px"
            >{{ post.user.firstname }} {{ post.user.lastname }} |
            {{ post.created_at | date }}</span
          >
        </div>
        <div matListItemLine class="msg" ItemLink>
          <span>{{ post.text }}</span>
        </div>
      </mat-list-item>
    </mat-list>
  </div>

  <div class="conversation-input">
    <mat-divider></mat-divider>
    <mat-form-field class="full-width" style="margin-top: 0">
      <mat-label>{{ 'Chat.typeMessage' | translate }}</mat-label>
      <textarea
        matInput
        (keydown.enter)="sendPost()"
        name="text"
        [(ngModel)]="text"
        [disabled]="disabled"
        cdkTextareaAutosize
      >
      </textarea>
      <mat-icon matSuffix (click)="sendPost()" *ngIf="!disabled" color="primary"
        >send</mat-icon
      >
    </mat-form-field>
  </div>
</div>
