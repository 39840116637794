import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CalendarEntityEvent } from 'src/app/model';
import { CalendarService } from 'src/app/services/calendar.service';
import { NotificationService } from 'src/app/services/notification.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-calendar-event',
  templateUrl: './calendar-event.component.html',
  styleUrls: ['./calendar-event.component.css'],
})
export class CalendarEventComponent implements OnInit {
  public isNew = false;
  public event: CalendarEntityEvent;
  public calendarId: number;
  constructor(
    private dialogRef: MatDialogRef<CalendarEventComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private calendarService: CalendarService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    if (this.data.event) {
      this.event = new CalendarEntityEvent();
      this.event.id = this.data.event.id;
      this.event.title = this.data.event.title;
      this.event.description = this.data.event.meta.description;
      this.event.start_date = this.data.event.meta.start_date;
      this.event.end_date = this.data.event.meta.end_date;
      this.event.calendar_event_participants =
        this.data.event.meta.participants;
      this.calendarId = this.data.event.meta.calendar_id;
      this.isNew = false;
    } else {
      this.event = new CalendarEntityEvent();
      this.event.calendar_event_participants = [];
      this.calendarId = this.data.calendar_id;
      this.isNew = true;
    }
  }

  public save() {
    if (this.isNew) {
      this.calendarService
        .addCalendarEvent(this.calendarId, this.event)
        .pipe(take(1))
        .subscribe((res) => {
          if (res.success) {
            this.dialogRef.close(this.event);
            this.notificationService.showSnackbarMessage(
              'Messages.successfulSave'
            );
          } else {
            this.notificationService.showSnackbarMessage('Messages.failSave');
          }
        });
    } else {
      this.calendarService
        .updateCalendarEvent(this.calendarId, this.event.id, this.event)
        .pipe(take(1))
        .subscribe((res) => {
          if (res.success) {
            this.dialogRef.close(this.event);
            this.notificationService.showSnackbarMessage(
              'Messages.successfulSave'
            );
          } else {
            this.notificationService.showSnackbarMessage('Messages.failSave');
          }
        });
    }
  }
}
