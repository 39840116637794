import { Component, Input, OnChanges } from '@angular/core';
import { ImageService } from '../../../../../services/image.service';
import { Photo } from '../../../../../models/image';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.css'],
})
export class ThumbnailComponent implements OnChanges {
  @Input() ITEM: any;
  @Input() ITEMID: any;
  @Input() allAttr: any;

  thumbnail: string = '';

  constructor(private imageService: ImageService) {}

  ngOnChanges() {
    this.getPhoto(this.ITEM.value);
  }

  onSelectedFile(event) {
    if (event.length > 0) {
      let newThumbnail: Photo = new Photo();
      const reader = new FileReader();
      reader.readAsDataURL(event[0]);
      reader.onloadend = () => {
        newThumbnail.fileBase64 = reader.result;
        newThumbnail.entdata_id = this.ITEMID;
        newThumbnail.entfield_id = this.ITEM.id;
        this.imageService
          .addThumbnail(newThumbnail)
          .pipe(take(1))
          .subscribe((res) => {
            this.ITEM.value = res.data.id;
            this.getPhoto(this.ITEM.value);
          });
      };
    }
  }

  public getPhoto(id: string) {
    if (id) {
      this.imageService
        .getImage(id)
        .pipe(take(1))
        .subscribe((res) => {
          this.thumbnail = res.data;
        });
    }
  }
}
