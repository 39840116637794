import { UserService } from 'src/app/services/user.service';
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { User } from 'src/app/model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-edit-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css'],
})
export class AvatarComponent implements OnInit {
  @Input() user: User;
  constructor(private userService: UserService) {}
  profileForm: UntypedFormGroup;
  base64Img: string = '';
  ngOnInit(): void {
    this.getAvatar(this.user.id);
    // this.userService.getAvatar
  }

  onSelectedFile(event) {
    if (event.length > 0) {
      const file = event[0];
      let avatarFile: File;
      avatarFile = event.item(0);
      let formData: FormData = new FormData();
      formData.append('file', avatarFile, avatarFile.name);
      formData.append('user_id', this.user.id + '');
      this.userService
        .changeAvatar(this.user.id, formData)
        .pipe(take(1))
        .subscribe((res) => {
          this.base64Img = res.data;
        });
    }
  }
  public getAvatar(userId: number) {
    this.userService
      .getAvatar(userId)
      .pipe(take(1))
      .subscribe((resAvatar) => {
        this.base64Img = resAvatar.data;
      });
  }
}
