import { Component, Input, OnInit } from '@angular/core';
import { Photo } from 'src/app/models/image';
import { ImageService } from 'src/app/services/image.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-gallery-image',
  templateUrl: './gallery-image.component.html',
  styleUrls: ['./gallery-image.component.css'],
})
export class GalleryImageComponent implements OnInit {
  @Input() photo: Photo;
  constructor(private imageService: ImageService) {}

  ngOnInit(): void {
    if (this.photo.id && !this.photo.fileBase64) {
      this.getPhoto();
    }
  }

  public getPhoto() {
    this.imageService
      .getImage(this.photo.id)
      .pipe(take(1))
      .subscribe((res) => {
        this.photo.fileBase64 = res.data;
      });
  }
}
