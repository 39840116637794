<mat-card class="userFormCard">
  <mat-card-subtitle>{{
    'LocalAdmin.userAvatar' | translate
  }}</mat-card-subtitle>

  <mat-card-content>
    <div>
      <div>
        <mat-icon class="addBtn iconAvatar" *ngIf="base64Img == ''"
          >person</mat-icon
        ><br /><br />
      </div>
      <div>
        <img
          class="addBtn imageAvatar"
          *ngIf="base64Img != ''"
          [src]="base64Img"
        />
      </div>
      <div>
        <button
          [disabled]="user.deleted_at != null"
          class="addBtn"
          type="button"
          mat-stroked-button
          (click)="dietafileInput.click()"
        >
          <span class="material-icons">add_a_photo</span>
        </button>
        <input
          hidden
          (change)="onSelectedFile($event.target.files)"
          #dietafileInput
          type="file"
          name="file"
          accept="image/x-png,image/gif,image/jpeg"
        />
      </div>
    </div>
  </mat-card-content>
</mat-card>
