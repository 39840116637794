import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ParentProductService {
  private checkboxState = false;

  setCheckboxState(state: boolean): void {
    this.checkboxState = state;
  }

  getCheckboxState(): boolean {
    return this.checkboxState;
  }
}
