<mat-list class="connected-items-list">
  <mat-list-item *ngFor="let item of items" class="listItem no-wrap">
    <mat-icon matListItemIcon>{{ ENTITY.icon }}</mat-icon>
    <div matListItemTitle style="display: flex; justify-content: space-between">
      <span (click)="previewLinkItem(item)"
        >#{{ item.id }} {{ getOnlyMbbileFlds(item)[0].value }}
      </span>
      <span
        *ngFor="let fld of getOnlyMbbileFlds(item).slice(1)"
        (click)="previewLinkItem(item)"
      >
        <span>
          <span *ngIf="fld.value"> {{ fld.value }} </span>
        </span>
      </span>
      <span (click)="previewLinkItem(item)" *ngIf="!(isHandset$ | async)">
        {{ getEntityNameTranslate(ENTITY) }}
      </span>
    </div>
    <button mat-icon-button (click)="unlinkEntityItem(item)" matListItemMeta>
      <mat-icon class="mat-24" color="warn">link_off</mat-icon>
    </button>
  </mat-list-item>
</mat-list>
