import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ERPMoneyaccount } from 'src/app/models/erpmoneyaccount';
import { ERPMoneydoc } from 'src/app/models/erpmoneydoc';
import { ERPMoneydoctype } from 'src/app/models/erpmoneydoctype';
import { ErpMoneyaccountService } from 'src/app/services/erp/erp-moneyaccount.service';
import { ErpMoneydocService } from 'src/app/services/erp/erp-moneydoc.service';
import { ErpMoneydoctypeService } from 'src/app/services/erp/erp-moneydoctype.service';
import { NotificationService } from 'src/app/services/notification.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-moneyaccount-doc-edit',
  templateUrl: './moneyaccount-doc-edit.component.html',
  styleUrls: ['./moneyaccount-doc-edit.component.css'],
})
export class MoneyaccountDocEditComponent implements OnInit {
  public moneydoctypes: ERPMoneydoctype[] = [];
  date1 = new FormControl(new Date());

  @Input() DOCID: string;
  public moneyDoc: ERPMoneydoc;
  public moneyAccounts: ERPMoneyaccount[] = [];

  constructor(
    private moneydoctypeService: ErpMoneydoctypeService,
    private moneyAccountService: ErpMoneyaccountService,
    private moneyDocService: ErpMoneydocService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    if (this.DOCID == 'new') {
      this.moneyDoc = new ERPMoneydoc();
      this.moneyDoc.code = '';
      this.moneyDoc.paid_at = new Date().toISOString();
    } else {
      this.moneyDoc = new ERPMoneydoc();
      this.moneyDoc.code = '';
      this.moneyDoc.paid_at = new Date().toISOString();
      this.getSavedDoc(+this.DOCID);
    }

    this.getdocTypes();
    this.getMoneyAccounts();
  }

  public fillTransactorItem(item: any) {
    this.moneyDoc.transactor_id = item.id;

    if (this.getTransactorSource() == 'account') {
      this.moneyDoc.account_name = item.account_name;
    } else if (this.getTransactorSource() == 'supplier') {
      this.moneyDoc.supplier_name = item.supplier_name;
    }
  }

  public clearTransactor() {
    this.moneyDoc.transactor_id = null;
    this.moneyDoc.account_name = null;
    this.moneyDoc.supplier_name = null;
  }

  public setTypeObj() {
    this.moneyDoc.moneydoctype = this.moneydoctypes.find(
      (x) => x.id == this.moneyDoc.moneydoctype_id
    );
  }

  public setMoneyaccountObj() {
    this.moneyDoc.moneyaccount = this.moneyAccounts.find(
      (x) => x.id == this.moneyDoc.moneyaccount_id
    );
  }

  public getSavedDoc(doc_id: number) {
    this.moneyDocService
      .getmoneyDocById(doc_id)
      .pipe(take(1))
      .subscribe((res) => {
        this.moneyDoc = res;
      });
  }

  public getdocTypes() {
    this.moneydoctypeService
      .getActiveMoneydoctypes()
      .pipe(take(1))
      .subscribe((res) => {
        this.moneydoctypes = res;
      });
  }

  public getMoneyAccounts() {
    this.moneyAccountService
      .getActiveMoneyaccounts()
      .pipe(take(1))
      .subscribe((res) => {
        this.moneyAccounts = res;
      });
  }

  public getTransactorSource() {
    let type = this.moneydoctypes.find(
      (x) => x.id == this.moneyDoc.moneydoctype_id
    );
    return type?.transactor_source ?? 'account';
  }

  public save() {
    this.moneyDocService
      .saveMoneyDoc(this.moneyDoc)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.success) {
          this.notificationService.showSnackbarMessage(
            'Messages.successfulSave'
          );
          this.moneyDoc = res.data;
        } else {
          this.notificationService.showSnackbarMessage('Messages.failSave');
        }
      });
  }

  public exportPdf() {
    this.moneyDocService
      .exportDocPdf(this.moneyDoc.id)
      .pipe(take(1))
      .subscribe((file) => {
        window.open(window.URL.createObjectURL(file));
      });
  }
}
