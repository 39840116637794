import { Component, Input, OnInit } from '@angular/core';
import { ERPMoneyaccount } from 'src/app/models/erpmoneyaccount';
import { ERPSpdoc } from 'src/app/models/erpspdoc';
import { ERPWarehouse } from 'src/app/models/erpwarehouse';
import { ERPMoneydoc } from 'src/app/models/erpmoneydoc';

@Component({
  selector: 'app-moneydoc-header-internal-trans',
  templateUrl: './moneydoc-header-internal-trans.component.html',
  styleUrls: ['./moneydoc-header-internal-trans.component.css'],
})
export class MoneyDocHeaderInternalTransComponent implements OnInit {
  @Input() moneyaccount: ERPMoneyaccount[] = [];
  @Input() moneyDoc: ERPMoneydoc;

  constructor() {}

  ngOnInit(): void {}
}
