import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { Field } from 'src/app/model';

@Component({
  selector: 'app-checkbox-filter',
  templateUrl: './checkbox-filter.component.html',
  styleUrls: ['./checkbox-filter.component.css'],
})
export class CheckboxFilterComponent implements OnInit, DoCheck {
  @Input() ITEM: Field;
  public checkBool: boolean;
  private oldValue: string = null;
  constructor() {}
  ngDoCheck(): void {
    let currentValue: string = this.ITEM.search_from;
    if (this.oldValue != currentValue) {
      this.checkBool =
        this.ITEM.search_from != null && this.ITEM.search_from == 'true';
      this.oldValue = this.ITEM.search_from;
    }
  }

  ngOnInit(): void {
    this.checkBool =
      this.ITEM.search_from != null && this.ITEM.search_from == 'true';
  }

  change(flag) {
    this.ITEM.search_from = flag.checked ? 'true' : 'false';
  }
}
