import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-yes-no-alert-diag',
  templateUrl: './yes-no-alert-diag.component.html',
  styleUrls: ['./yes-no-alert-diag.component.css'],
})
export class YesNoAlertDiagComponent implements OnInit {
  title: string;
  message: string;

  constructor(
    private dialogRef: MatDialogRef<YesNoAlertDiagComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.title = this.data.title;
    this.message = this.data.message;
  }

  public yes() {
    this.dialogRef.close(true);
  }

  public no() {
    this.dialogRef.close(false);
  }
}
