<mat-card style="margin-top: 10px">
  <mat-card-subtitle style="display: flex; align-items: center">
    <span>
      {{ 'Company.connectedEntities' | translate }}
    </span>
    <button
      *ngIf="entityMenuSelectorLink?.menu"
      mat-icon-button
      [disabled]="currentEntItem && currentEntItem.isActive == 0"
      [matMenuTriggerFor]="entityMenuSelectorLink?.menu"
      [matTooltip]="'Generic.newLink' | translate"
    >
      <mat-icon color="primary">add_link</mat-icon>
    </button>
    <button
      *ngIf="entityMenuSelectorNewItem?.menu"
      mat-icon-button
      [disabled]="currentEntItem && currentEntItem.isActive == 0"
      [matMenuTriggerFor]="entityMenuSelectorNewItem?.menu"
      [matTooltip]="'welcome.quickCreator' | translate"
    >
      <mat-icon color="primary">library_add</mat-icon>
    </button>
  </mat-card-subtitle>

  <div class="fullDiv flex" *ngIf="avConnectedEntities.length > 0">
    <div class="sideBar background-alternate-warn">&nbsp;</div>
    <div class="rightDiv" cdkDropListGroup>
      <div *ngFor="let entity of avConnectedEntities" class="spintDiv">
        <div
          class="text-primary flex connctedTitle"
          (click)="entity.isExpanded = !entity.isExpanded"
        >
          <div class="sprintCircle background-primary">
            <mat-icon *ngIf="entity.isExpanded">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="!entity.isExpanded">keyboard_arrow_right</mat-icon>
          </div>
          <span
            >{{ getEntityNameTranslate(entity) }} ({{ entity.counter }})</span
          >
        </div>
        <div [class.hideSprint]="!entity.isExpanded" *ngIf="entity.isExpanded">
          <app-data-connected-items
            [ENTID]="ITEMID"
            [ENTITY]="entity"
            (REFRESH)="getEntCodeConnections()"
          >
          </app-data-connected-items>
        </div>
      </div>
      <div class="alternate-theme"></div>
    </div>
  </div>
</mat-card>

<app-entity-menu-selector
  #addlinkMenu
  ICON="add_link"
  (SELECTENTITY)="selectEntityIDiag($event)"
>
</app-entity-menu-selector>
<app-entity-menu-selector
  #addNewItemMenu
  ICON="library_add"
  (SELECTENTITY)="quickCreateItem($event)"
>
</app-entity-menu-selector>
