<table class="full-width">
  <tbody>
    <tr>
      <td>
        <span>{{ 'Generic.download' | translate }}</span>
      </td>
      <td>
        <span>
          <button mat-icon-button color="primary" (click)="createPdf()">
            <mat-icon>save_alt</mat-icon>
          </button>
        </span>
      </td>
    </tr>
  </tbody>
</table>
