<div class="sprint-list" style="height: -webkit-fill-available; overflow-y: scroll; overflow-x: hidden;">
  <div class="sideBar background-alternate-warn">&nbsp;</div>
  <div class="rightDiv">
    <div *ngIf="schedule">
      <div *ngFor="let sprint of schedule.sprints" class="spintDiv">
        <div class="text-alternate-accent">
          <div
            class="sprintCircle background-alternate-accent"
            (click)="sprint.showSprint = !sprint.showSprint"
          >
            <mat-icon *ngIf="sprint.showSprint">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="!sprint.showSprint">keyboard_arrow_right</mat-icon>
          </div>
          {{ sprint.name }}

          <button
            mat-icon-button
            color="warn"
            class="spintDelbtn"
            (click)="delSprint(sprint)"
            *ngIf="sprint.id && currentUser.id == schedule.owner_id"
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
        </div>
        <div [class.hideSprint]="!sprint.showSprint" *ngIf="sprint.showSprint">
          <app-prod-schedule-sprint
            (refreshData)="getOrdersBySchedule()"
            [SPRINT]="sprint"
            [SPRINTS]="schedule.sprints"
            [SCHEDULEID]="schedule.id"
          ></app-prod-schedule-sprint>
        </div>
      </div>
    </div>

    <div *ngIf="project">
      <div *ngFor="let sprint of project.sprints" class="spintDiv">
        <div class="text-alternate-accent">
          <div
            class="sprintCircle background-alternate-accent"
            (click)="sprint.showSprint = !sprint.showSprint"
          >
            <mat-icon *ngIf="sprint.showSprint">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="!sprint.showSprint">keyboard_arrow_right</mat-icon>
          </div>
          {{ sprint.name }}
          ({{ durationHelper.getCompletedDuration(sprint).toFixed(1) }}/{{
            durationHelper.getIdialDuration(sprint).toFixed(1)
          }}{{ 'Projects.hours' | translate }})

          <button
            mat-icon-button
            color="warn"
            class="spintDelbtn"
            (click)="delSprint(sprint)"
            *ngIf="sprint.id && currentUser.id == project.owner_id"
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
        </div>
        <div [class.hideSprint]="!sprint.showSprint" *ngIf="sprint.showSprint">
          <app-project-sprint
            (refreshData)="getTaskByProject()"
            [SPRINT]="sprint"
            [ALLSPRINT]="project.sprints"
            [PROJECTID]="project.id"
          ></app-project-sprint>
        </div>
      </div>
    </div>

    <div class="alternate-theme">
      <button
        class="AddsprintBtn"
        mat-mini-fab
        color="accent"
        (click)="newSprintDiag()"
      >
        <mat-icon style="color: white">add</mat-icon>
      </button>
    </div>

    <button
      *ngIf="ITEMID != 'new'"
      mat-fab
      color="primary"
      class="add-button"
      matTooltip="{{ 'Generic.add' | translate }}"
      (click)="onCreate()"
    >
      <i class="material-icons">add</i>
    </button>
  </div>
</div>
