import { Component, Input, OnInit } from '@angular/core';
import { Company } from 'src/app/model';
import { CompanyService } from 'src/app/services/company.service';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-department-field',
  templateUrl: './department-field.component.html',
  styleUrls: ['./department-field.component.css'],
})
export class DepartmentFieldComponent implements OnInit {
  public company: Company;
  @Input() ITEM: any;
  @Input() disabled: boolean = false;
  public showChart: boolean = false;
  private routesForAllDepartmentsProperty: string[] = [
    'document',
    'report',
    'account',
    'calendar',
    'maintenance_file',
  ];
  public showAllDepartmentProperty: boolean = false;
  constructor(
    private companyService: CompanyService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.ITEM.value = +this.ITEM.value;
    this.getCompany();
    this.enableAllDepartmentsProperty();
  }

  private getCompany() {
    this.companyService
      .getCompanyForUser()
      .pipe(take(1))
      .subscribe((res) => {
        this.company = res;
      });
  }

  public toggleChart(event: any) {
    event.stopPropagation();
    this.showChart = !this.showChart;
  }

  private enableAllDepartmentsProperty() {
    let currentRoute = this.router.url.substring(1);
    this.showAllDepartmentProperty =
      this.routesForAllDepartmentsProperty.includes(currentRoute);
  }
}
