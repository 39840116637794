import { Component, OnInit, Input } from '@angular/core';
import { ERPSpdoc } from 'src/app/models/erpspdoc';
import { ERPMoneydoc } from 'src/app/models/erpmoneydoc';
import { ERPMoneyaccount } from 'src/app/models/erpmoneyaccount';

@Component({
  selector: 'app-doc-header-common',
  templateUrl: './doc-header-common.component.html',
  styleUrls: ['./doc-header-common.component.css'],
})
export class DocHeaderCommonComponent implements OnInit {
  @Input() spdoc: ERPSpdoc;
  @Input() ransactorSource: string;
  @Input() moneyDoc: ERPMoneydoc;
  @Input() moneyaccounts: ERPMoneyaccount[] = [];

  constructor() {}

  ngOnInit(): void {}

  public fillTransactorItem(item: any) {
    this.spdoc.transactor_id = item.id;
    if (this.ransactorSource == 'account') {
      this.spdoc.account_name = item.account_name;
    } else if (this.ransactorSource == 'supplier') {
      this.spdoc.supplier_name = item.supplier_name;
    }
  }

  public clearTransactor() {
    this.spdoc.transactor_id = null;
    this.spdoc.account_name = null;
    this.spdoc.supplier_name = null;
  }
}
