import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WebcamImage, WebcamComponent } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-camera-capture',
  templateUrl: './camera-capture.component.html',
  styleUrls: ['./camera-capture.component.css'],
})
export class CameraCaptureComponent implements OnInit, AfterViewInit {
  title = 'gfgangularwebcam';

  public webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();

  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;

  @ViewChild(WebcamComponent) webcamComponent!: WebcamComponent;

  constructor(
    private dialogRef: MatDialogRef<CameraCaptureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private breakpointObserver: BreakpointObserver
  ) {}
  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.isHandset$.subscribe((x) => {
      if (x) {
        this.dialogRef.updateSize('100%');
      }
    });
  }

  triggerSnapshot(): void {
    this.trigger.next();
  }

  handleImage(webcamImage: WebcamImage): void {
    console.info('Saved webcam image', webcamImage);
    this.webcamImage = webcamImage;
    this.dialogRef.close(webcamImage.imageAsDataUrl);
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  public goBack() {
    this.dialogRef.close(null);
  }

  fileChangeEvent(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          console.log(img_height, img_width);

          const imgBase64Path = e.target.result;
          this.cardImageBase64 = imgBase64Path;
          this.isImageSaved = true;
          this.dialogRef.close(imgBase64Path);
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }
}
