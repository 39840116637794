import { Component, Input, OnInit } from '@angular/core';
import { ERPSpdoc } from 'src/app/models/erpspdoc';
import { ERPWarehouse } from 'src/app/models/erpwarehouse';

@Component({
  selector: 'app-doc-header-internal-trans',
  templateUrl: './doc-header-internal-trans.component.html',
  styleUrls: ['./doc-header-internal-trans.component.css'],
})
export class DocHeaderInternalTransComponent implements OnInit {
  @Input() warehouses: ERPWarehouse[] = [];
  @Input() spdoc: ERPSpdoc;

  constructor() {}

  ngOnInit(): void {}
}
