<div class="buttonPanel mat-elevation-z3">
  <button mat-button [mat-dialog-close]="null">
    <mat-icon>keyboard_backspace</mat-icon>
    <span *ngIf="!(isHandset$ | async)">{{ 'Generic.back' | translate }}</span>
  </button>
  <mat-divider [vertical]="true"></mat-divider>
</div>

<div mat-dialog-actions>
  <mat-table [dataSource]="fields">
    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>
        {{ 'Company.fldName' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ fldNameTranslation(element) }}
      </mat-cell>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef>
        {{ 'Company.fldType' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ fldTypeTranslation(element) }}
      </mat-cell>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="code">
      <mat-header-cell *matHeaderCellDef>
        {{ 'Company.fldCode' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.fld_code }} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      [mat-dialog-close]="row"
      class="rowSelect"
    ></mat-row>
  </mat-table>
</div>
