<mat-selection-list
  #favoriteSelectionList
  [(ngModel)]="companyService.favoriteModules"
>
  <mat-list-option
    *ngFor="let module of companyService.userModules"
    (click)="selectFavorite($event)"
    [value]="module"
    color="primary"
  >
    <mat-icon matListItemIcon style="color: black">{{
      module.maticon
    }}</mat-icon>
    <div matListItemTitle>{{ module.title }}</div>
  </mat-list-option>
</mat-selection-list>
