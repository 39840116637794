import { Component, Input, OnInit } from '@angular/core';
import { ReportService } from 'src/app/services/report.service';
import { Location } from '@angular/common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.css'],
})
export class ReportViewComponent implements OnInit {
  @Input() ITEMID: string;

  public viewHTML: SafeHtml;
  constructor(
    private reportService: ReportService,
    private __location: Location,
    private breakpointObserver: BreakpointObserver,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.getview();
  }

  private getview() {
    this.reportService
      .getReportView(this.ITEMID)
      .pipe(take(1))
      .subscribe((res) => {
        this.viewHTML = res;
        this.viewHTML = this.sanitizer.bypassSecurityTrustHtml(res);
      });
  }

  public downloadPdf() {
    this.reportService
      .downloadReportPdf(this.ITEMID)
      .pipe(take(1))
      .subscribe((file) => {
        window.open(window.URL.createObjectURL(file));
      });
  }

  public goBack() {
    this.__location.back();
  }
}
