import { Component, Input, OnInit } from '@angular/core';
import { TimeHelper } from 'src/app/common/helper';

@Component({
  selector: 'app-datetime',
  templateUrl: './datetime.component.html',
  styleUrls: ['./datetime.component.css'],
})
export class DatetimeComponent implements OnInit {
  @Input() ITEM: any;
  @Input() disabled: boolean = false;
  public selectedDate: Date;
  public isTaskEndDate: boolean = false;
  constructor() {}

  ngOnInit(): void {
    if (this.ITEM.value) {
      this.selectedDate = new Date(this.ITEM.value);
    }
    this.isTaskEndDate = this.ITEM.fld_code_ent === 'task_enddate';
  }

  public change(ev) {
    this.ITEM.value = TimeHelper.getDatetimeString(this.selectedDate);
  }
}
