import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PagedData } from 'src/app/model';
import { ERPMoneydoc } from 'src/app/models/erpmoneydoc';
import { EntitySelectorDiagComponent } from '../../entity-selector-diag/entity-selector-diag.component';
import { MoneydocFilter } from '../../filters';
import { TimeHelper } from '../../helper';

@Component({
  selector: 'app-moneyaccount-doc-grid',
  templateUrl: './moneyaccount-doc-grid.component.html',
  styleUrls: ['./moneyaccount-doc-grid.component.css'],
})
export class MoneyaccountDocGridComponent implements OnInit {
  public gridColumns: any[] = [];

  @Input() filters: MoneydocFilter = new MoneydocFilter();
  @Input() docs: PagedData<ERPMoneydoc>;

  @Output() getData = new EventEmitter<MoneydocFilter>();
  @Output() OnmoneydocSelect = new EventEmitter<number>();
  constructor(
    private translateService: TranslateService,
    public router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getGridColumns();
  }

  private getGridColumns() {
    this.gridColumns = [
      { title: '#', attr: 'id', view: true, mobileView: false, type: 'data' },
      {
        title: this.translateService.instant('ERPSpdoctype.doc_type'),
        attr: 'typecode',
        view: true,
        mobileView: false,
        type: 'data',
      },
      {
        title: this.translateService.instant('ERPSpdoctype.code'),
        attr: 'code',
        view: true,
        mobileView: true,
        type: 'data',
      },
      {
        title: this.translateService.instant('ERP.transactor'),
        attr: 'transactor',
        view: true,
        mobileView: true,
        type: 'data',
      },
      {
        title: this.translateService.instant('ERPMoneyaccounts.total'),
        attr: 'total_price',
        view: true,
        mobileView: true,
        type: 'data',
      },
      {
        title: this.translateService.instant('ERPMoneyaccounts.paid_date'),
        attr: 'paid_at',
        view: true,
        mobileView: false,
        type: 'date',
      },
    ];
  }

  public selectEntityItemDiag() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      entityCode: 'account',
    };

    const dialogRef = this.dialog.open(
      EntitySelectorDiagComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        this.filters.transactor_id = result.id;
        this.getData.emit(this.filters);
      }
    });
  }

  public refreshList(filters: MoneydocFilter) {
    this.filters = filters;
    this.getData.emit(this.filters);
  }
  public onRowClick(ev) {
    this.OnmoneydocSelect.emit(ev.id);
  }

  public fillTransactorItem(item: any) {
    this.filters.transactor_id = item.id;
    this.getData.emit(this.filters);
  }

  convertDateToString(event: any, mode: string) {
    if (mode === 'from') {
      this.filters.from =
        event.value !== undefined && event.value !== null
          ? TimeHelper.getDateString(event.value)
          : null;
    }
    if (mode === 'to') {
      this.filters.to =
        event.value !== undefined && event.value !== null
          ? TimeHelper.getDateString(event.value)
          : null;
    }
    this.refreshList(this.filters);
  }
}
