<h1 mat-dialog-title>{{ 'Generic.availableDimensions' | translate }}</h1>
<div mat-dialog-content>
  <div class="scrollable-container">
    <mat-table
      [dataSource]="dialogData.colors"
      class="mat-elevation-z8 full-width"
    >
      <!-- Static Color Column -->
      <ng-container matColumnDef="color">
        <mat-header-cell *matHeaderCellDef class="sticky-column">
          {{ 'Generic.dimensions' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let color" class="sticky-column">
          {{ color }}
        </mat-cell>
      </ng-container>

      <!-- Dynamic Size Columns -->
      <ng-container *ngFor="let size of dialogData.sizes" [matColumnDef]="size">
        <mat-header-cell *matHeaderCellDef> {{ size }} </mat-header-cell>
        <mat-cell *matCellDef="let color">
          <div class="cell-container">
            <mat-form-field class="full-width">
              <input
                matInput
                type="number"
                [(ngModel)]="dialogData.tableData[color][size]"
                placeholder="Qty"
                [disabled]="!dialogData.validCombinations.has(color + '/' + size)"
              />
            </mat-form-field>
            <span class="stock-value">
              Stock: {{ dialogData.tableData[color] && dialogData.tableData[color][size] ? dialogData.tableData[color][size].stock : 0 }}
            </span>
          </div>
        </mat-cell>
      </ng-container>
      

      <!-- Header and Row Definitions -->
      <mat-header-row *matHeaderRowDef="displayedPopColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedPopColumns"></mat-row>
    </mat-table>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>{{ 'Generic.close' | translate }}</button>
  <button mat-button color="primary" (click)="confirmSelection(dialogData)">
    {{ 'Generic.add' | translate }}
  </button>
</div>
