import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DocumentFile, User } from 'src/app/model';
import { EntityService } from 'src/app/services/entity.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-file-history',
  templateUrl: './file-history.component.html',
  styleUrls: ['./file-history.component.css'],
})
export class FileHistoryComponent implements OnInit {
  @Input() ITEM: any;
  @Input() ITEMID: any;
  @Input() OWNER: User;
  @Input() disabled: boolean = false;
  profileForm: UntypedFormGroup;
  files: DocumentFile[] = [];
  latstDoc: DocumentFile;
  error: string;
  file: File = null;
  fileUpload = { status: '', message: '', filename: '' };

  constructor(
    private fb: UntypedFormBuilder,
    private fileUploadService: FileUploadService,
    private entityService: EntityService
  ) {}

  ngOnInit(): void {
    this.profileForm = this.fb.group({
      name: [''],
      profile: [''],
    });

    if (this.ITEMID != 'new') {
      this.getHistoryFiles();
    }
  }

  getHistoryFiles() {
    this.entityService
      .getHistoryFiles(this.ITEMID, this.ITEM)
      .pipe(take(1))
      .subscribe((resFiles) => {
        if (resFiles.length > 0) {
          this.latstDoc = resFiles.splice(0, 1)[0];
          this.fileUploadService.fileForPreviewId.next(this.latstDoc.id);
          this.files = resFiles;
        }
      });
  }

  onSelectedFile(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.profileForm.get('profile').setValue(file);
      for (let file of event.target.files) {
        let docFIle: DocumentFile = new DocumentFile();
        docFIle.filename = file.name;
        this.upload(docFIle);
      }
    }
  }

  upload(attachment: any) {
    const formData = new FormData();

    if (this.latstDoc && this.latstDoc.id) {
      this.files.unshift(this.latstDoc);
    }

    formData.append('profile', this.profileForm.get('profile').value);

    this.fileUploadService
      .upload(formData)
      .subscribe(
        (res) => (this.fileUpload = res),
        (err) => (this.error = err)
      )
      .add(() => {
        attachment.file_path = this.fileUpload.filename;
        if (this.fileUpload.filename) {
          this.latstDoc = new DocumentFile();
          this.latstDoc.filename = attachment.filename;
          this.latstDoc.path = attachment.file_path;

          this.ITEM.newfile = attachment;
          this.ITEM.value = attachment.filename;
        }
      });
  }

  public async downloadFile(fileId: number) {
    await this.fileUploadService
      .downloadFIle(fileId)
      .pipe(take(1))
      .subscribe((file) => {
        this.file = file;
        this.fileUploadService.downloadToDevice(
          this.file,
          this.latstDoc.filename
        );
      });
  }
}
