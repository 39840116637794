import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { ChatMsg, ChatPopup, PagedData, User } from '../model';
import { SpinnerType } from '../common/enums';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatService extends BaseService {
  // public showPopupChatroom:boolean = false;
  // public userForPopup:User;
  public chatPopups: ChatPopup[] = [];
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getChatHistory(user: User): Observable<PagedData<ChatMsg>> {
    return this.get<PagedData<ChatMsg>>(
      this.apiUrl + 'chat/user/' + user.id,
      SpinnerType.None
    );
  }

  public newChatMsg(chatMsg: ChatMsg): Observable<any> {
    return this.post<any>(this.apiUrl + 'chat', chatMsg, SpinnerType.None);
  }
}
