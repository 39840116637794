import { EntityService } from 'src/app/services/entity.service';
import { Component, Input } from '@angular/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-pdf-export',
  templateUrl: './pdf-export.component.html',
  styleUrls: ['./pdf-export.component.css'],
})
export class PdfExportComponent {
  @Input() ITEM: any;
  @Input() ITEMID: any;
  @Input() disabled: boolean = false;
  constructor(private entityService: EntityService) {}

  public createPdf() {
    this.entityService
      .exportItemToPdf([+this.ITEMID], this.ITEM.id)
      .pipe(take(1))
      .subscribe((file) => {
        if (file) {
          window.open(window.URL.createObjectURL(file));
        }
      });
  }
}
