import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MoneydocFilter } from 'src/app/common/filters';
import { PagedData, ReturnData } from 'src/app/model';
import { ERPMoneydoc } from 'src/app/models/erpmoneydoc';
import { BaseService } from '../base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErpMoneydocService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public saveMoneyDoc(
    moneydoc: ERPMoneydoc
  ): Observable<ReturnData<ERPMoneydoc>> {
    return this.post<ReturnData<ERPMoneydoc>>(
      this.apiUrl + 'erp/moneydocs',
      moneydoc
    );
  }

  public exportDocPdf(moneydoc_id: number): Observable<any> {
    return this.getFile<any>(
      this.apiUrl + 'erp/export/moneydocs/' + moneydoc_id
    );
  }

  public getMoneydocs(
    filters: MoneydocFilter
  ): Observable<PagedData<ERPMoneydoc>> {
    let queryFrom: string = filters.from ? '&from=' + filters.from : '';
    let queryTo: string = filters.to ? '&to=' + filters.to : '';
    let queryTransactor: string = filters.transactor_id
      ? '&transactor=' + filters.transactor_id
      : '';
    return this.get<PagedData<ERPMoneydoc>>(
      this.apiUrl +
        'erp/moneydocs?page=' +
        filters.currentPage +
        '&pageSize=' +
        filters.pageSize +
        queryFrom +
        queryTo +
        queryTransactor
    );
  }

  public getmoneyDocById(doc_id: number): Observable<ERPMoneydoc> {
    return this.get<ERPMoneydoc>(this.apiUrl + 'erp/moneydoc/' + doc_id);
  }

  public multiReplicateMoneydocItem(
    moneydocId: string,
    amount: number
  ): Observable<ReturnData<number[]>> {
    return this.get<ReturnData<number[]>>(
      this.apiUrl +
        'erp/copy-moneydoc-item/erp_docs/moneydocs/edit/' +
        moneydocId +
        '/' +
        amount
    );
  }
}
