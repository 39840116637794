import {
  Entdata,
  PagedData,
  ReturnData,
  SemiProduct,
  SemiProductCategory,
} from '../model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { SemiProductsFilter } from '../common/filters';
import { SpinnerType } from '../common/enums';
import { RecipeLine } from '../models/recipeline';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getSemiProductCategoriesWithSemiProds(): Observable<
    SemiProductCategory[]
  > {
    return this.get<SemiProductCategory[]>(
      this.apiUrl + 'admin/productconf/categories'
    );
  }

  public getSemiProductCategories(): Observable<SemiProductCategory[]> {
    return this.get<SemiProductCategory[]>(
      this.apiUrl + 'productconf/categories'
    );
  }

  public getSemiProductsByCatAndComp(
    catId: number,
    productFilter: SemiProductsFilter
  ): Observable<PagedData<SemiProduct>> {
    return this.post<PagedData<SemiProduct>>(
      this.apiUrl +
        'productconf/semiprod/' +
        catId +
        '?page=' +
        productFilter.currentPage,
      productFilter,
      SpinnerType.None
    );
  }

  public addSemiProductCategory(
    cat: SemiProductCategory
  ): Observable<ReturnData<SemiProductCategory>> {
    return this.post<ReturnData<SemiProductCategory>>(
      this.apiUrl + 'admin/productconf/category',
      cat
    );
  }
  public updateSemiProductCategory(
    cat: SemiProductCategory
  ): Observable<ReturnData<SemiProductCategory>> {
    return this.put<ReturnData<SemiProductCategory>>(
      this.apiUrl + 'admin/productconf/category',
      cat
    );
  }

  public addSemiProduct(
    prod: SemiProduct
  ): Observable<ReturnData<SemiProduct>> {
    return this.post<ReturnData<SemiProduct>>(
      this.apiUrl + 'admin/productconf/semiproduct',
      prod
    );
  }

  public updateSemiProduct(
    prod: SemiProduct
  ): Observable<ReturnData<SemiProduct>> {
    return this.put<ReturnData<SemiProduct>>(
      this.apiUrl + 'admin/productconf/semiproduct',
      prod
    );
  }

  public deleteSemiProdCategories(
    category_id: number
  ): Observable<ReturnData<string>> {
    return this.delete<ReturnData<string>>(
      this.apiUrl + 'admin/productconf/category/' + category_id
    );
  }

  public updateComp(prod: SemiProduct): Observable<ReturnData<SemiProduct>> {
    return this.put<ReturnData<SemiProduct>>(
      this.apiUrl + 'admin/productconf/comp/' + prod.id,
      prod.compatibilities
    );
  }

  public newProductFromSemiProds(obj: any): Observable<ReturnData<Entdata>> {
    return this.post<ReturnData<Entdata>>(this.apiUrl + 'productconf', obj);
  }

  public getSemiProductList(prodId: string): Observable<SemiProduct[]> {
    return this.get<SemiProduct[]>(this.apiUrl + 'productconf/' + prodId);
  }

  public getSavedRecipeLines(entdataId: string): Observable<RecipeLine[]> {
    return this.get<RecipeLine[]>(this.apiUrl + 'prodrecipe/' + entdataId);
  }
}
