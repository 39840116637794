import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseFilter } from 'src/app/common/filters';
import { FilterHelper } from 'src/app/common/helper';
import { ReturnData } from 'src/app/model';
import { ERPMoneydoctype } from 'src/app/models/erpmoneydoctype';
import { ERPSpdoctype } from 'src/app/models/erpspdoctype';
import { BaseService } from '../base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErpMoneydoctypeService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getActiveMoneydoctypes(): Observable<ERPMoneydoctype[]> {
    return this.get<ERPMoneydoctype[]>(this.apiUrl + 'erp/moneydoctypes');
  }

  public storeMoneydoctype(
    moneydoctype: ERPMoneydoctype
  ): Observable<ReturnData<ERPMoneydoctype>> {
    return this.post<ReturnData<ERPMoneydoctype>>(
      this.apiUrl + 'erp/moneydoctypes',
      moneydoctype
    );
  }

  public updateMoneydoctype(
    moneydoctype: ERPMoneydoctype
  ): Observable<ReturnData<ERPMoneydoctype>> {
    return this.put<ReturnData<ERPMoneydoctype>>(
      this.apiUrl + 'erp/moneydoctypes/' + moneydoctype.id,
      moneydoctype
    );
  }

  public getAllMoneydoctypes(
    filters: BaseFilter
  ): Observable<ERPMoneydoctype[]> {
    let queryString = FilterHelper.QueryStringHelper(filters);
    return this.get<ERPMoneydoctype[]>(
      this.apiUrl + 'erp/moneydoctypes_all' + queryString
    );
  }

  public getSingleMoneydoctype(id: number): Observable<ERPMoneydoctype> {
    return this.get<ERPMoneydoctype>(this.apiUrl + 'erp/moneydoctypes/' + id);
  }
}
