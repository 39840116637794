import { Report } from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public newReport(report: Report): Observable<any> {
    return this.post<any>(this.apiUrl + 'admin/report', report);
  }

  public getReportView(reportId: string): Observable<any> {
    return this.getView<any>(this.apiUrl + 'report/view/' + reportId);
  }

  public downloadReportPdf(reportId: string): Observable<any> {
    return this.getFile<any>(this.apiUrl + 'report/pdf/' + reportId);
  }
}
