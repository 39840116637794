import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploadService } from '../../../../../services/file-upload.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.css'],
})
export class FilePreviewComponent implements OnInit {
  pdfSrc: any = null;
  imgSrc: any = null;
  showPDFViewer = false;

  constructor(
    private fileUploadService: FileUploadService,
    private destroyRef: DestroyRef,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.fileUploadService.fileForPreviewId
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((fileId) => {
        this.getFile(fileId);
      });
  }

  private getFile(fileId: number) {
    this.fileUploadService
      .downloadFIle(fileId)
      .pipe(take(1))
      .subscribe((x) => {
        if (x.type === 'application/pdf') {
          this.handlePDF(x);
        } else if (x.type === 'image/jpeg' || x.type === 'image/png') {
          this.handleImage(x);
        }
      });
  }

  private handlePDF(x: any) {
    console.log(x);
    // const reader = new FileReader();
    // reader.onloadend = () => {
    //   //this.pdfSrc = reader.result;
    //   if (typeof reader.result === 'string') {
    //     this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result);
    //   }
    //   setTimeout(() => this.showPDFViewer = true, 1000);
    //   setTimeout(() => this.showPDFViewer = false, 3000);
    //   setTimeout(() => this.showPDFViewer = true, 5000);
    // };
    // reader.readAsDataURL(x);

    this.showPDFViewer = true;
    let objectURL = URL.createObjectURL(x);
    this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(objectURL);
  }

  private handleImage(x: any) {
    console.log(x);
    this.imgSrc = URL.createObjectURL(x);
  }
}
