import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InstallationLine } from '../models/installationline';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InstallationService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getSavedLines(entdataId: string): Observable<InstallationLine[]> {
    return this.get<InstallationLine[]>(
      this.apiUrl + 'installationlines/' + entdataId
    );
  }
}
