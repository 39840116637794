import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WarehouseProductHistoryComponent } from 'src/app/common/erp/warehouse-product-history/warehouse-product-history.component';
import { ERPWhStocks } from 'src/app/models/erpwarehouse';
import { ErpWarehouseService } from 'src/app/services/erp/erp-warehouse.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-erp-product-stock-field',
  templateUrl: './erp-product-stock-field.component.html',
  styleUrls: ['./erp-product-stock-field.component.css'],
})
export class ErpProductStockFieldComponent implements OnInit {
  public warehouses: ERPWhStocks[] = [];
  @Input() ITEM: any;
  @Input() ITEMID: any;
  @Input() disabled: boolean = false;

  displayedColumns: string[] = ['id', 'title', 'stock', 'details'];
  constructor(
    private warehouseService: ErpWarehouseService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.ITEMID != 'new') {
      this.getStock();
    }
  }

  private getStock() {
    this.warehouseService
      .getProductWhStocks(+this.ITEMID)
      .pipe(take(1))
      .subscribe((res) => {
        this.warehouses = res;
      });
  }

  public viewHistory(warehouse_id: number) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      product_id: this.ITEMID,
      warehouse_id: warehouse_id,
    };

    const dialogRef = this.dialog.open(
      WarehouseProductHistoryComponent,
      dialogConfig
    );
  }
}
