<div class="filtersDiv">
  <mat-form-field class="filter">
    <mat-label>{{ 'Generic.from' | translate }}</mat-label>
    <input
      matInput
      [ngModel]="filters.from"
      [matDatepicker]="pickerFrom"
      (dateChange)="convertDateToString($event, 'from')"
    />
    <mat-datepicker-toggle
      matIconSuffix
      [for]="pickerFrom"
    ></mat-datepicker-toggle>
    <mat-datepicker #pickerFrom></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="filter">
    <mat-label>{{ 'Generic.to' | translate }}</mat-label>
    <input
      matInput
      [(ngModel)]="filters.to"
      [matDatepicker]="pickerTo"
      (dateChange)="convertDateToString($event, 'to')"
    />
    <mat-datepicker-toggle
      matIconSuffix
      [for]="pickerTo"
    ></mat-datepicker-toggle>
    <mat-datepicker #pickerTo></mat-datepicker>
  </mat-form-field>

  <button mat-icon-button color="primary" (click)="selectEntityItemDiag()">
    <mat-icon>person_search</mat-icon>
  </button>
</div>

<div>
  <app-grid
    *ngIf="gridColumns"
    [ID]="'erp_admin_warehouse'"
    [ELEMENT_DATA]="docs"
    [ATTR]="gridColumns"
    [FILTERS]="filters"
    (getData)="refreshList($event)"
    [showSearch]="true"
    (ONROWCLICK)="onRowClick($event)"
    [SHOWTRASHFILTER]="false"
    [PAGESIZEOPTIONS]="[20, 50, 100]"
  >
  </app-grid>
</div>
