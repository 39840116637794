import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpinnerType } from '../common/enums';
import { ReturnData } from '../model';
import { Photo } from '../models/image';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getImageList(entfield_id, entdata_id): Observable<Photo[]> {
    return this.get<Photo[]>(
      this.apiUrl + 'entity/images/' + entfield_id + '/' + entdata_id
    );
  }

  public getImage(image_id): Observable<any> {
    return this.get<any>(
      this.apiUrl + 'entity/image/' + image_id,
      SpinnerType.None
    );
  }

  public addImage(image: Photo): Observable<ReturnData<any>> {
    return this.post<ReturnData<any>>(
      this.apiUrl +
        'entity/image/' +
        image.entfield_id +
        '/' +
        image.entdata_id,
      image
    );
  }

  public addThumbnail(thumbnail: Photo): Observable<ReturnData<any>> {
    return this.post<ReturnData<any>>(
      this.apiUrl +
        'entity/thumbnail/' +
        thumbnail.entfield_id +
        '/' +
        thumbnail.entdata_id,
      thumbnail
    );
  }

  public delImage(image_id): Observable<ReturnData<any>> {
    return this.delete<ReturnData<any>>(
      this.apiUrl + 'entity/image/' + image_id
    );
  }

  public downloadImage(image_id: number): Observable<any> {
    return this.getFile<any>(this.apiUrl + 'entity/downloadimage/' + image_id);
  }

  public rotateImage(image_id): Observable<any> {
    return this.get<any>(this.apiUrl + 'entity/rotate/' + image_id);
  }
}
