import { Department } from '../../../model';
import { CompanyService } from '../../../services/company.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { PagedData, User, UserJoinDepartment } from 'src/app/model';
import { UsersFilter } from 'src/app/common/filters';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PermitionBulkAssignComponent } from './permition-bulk-assign/permition-bulk-assign.component';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css'],
})
export class UserListComponent implements OnInit {
  @Input() companyIdToOpen: number;
  @Input() showToolbar: boolean = true;
  users: PagedData<UserJoinDepartment> = new PagedData();
  userFilters: UsersFilter = new UsersFilter();
  departments: Department[] = [];
  constructor(
    private userSerice: UserService,
    private router: Router,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private __location: Location,
    private breakpointObserver: BreakpointObserver,
    private companyService: CompanyService,
    public dialog: MatDialog,
    public notificationService: NotificationService
  ) {}

  gridColumns = [
    { title: '#', attr: 'id', view: true, type: 'data' },
    {
      title: this.translateService.instant('Register.firstname'),
      attr: 'firstname',
      view: true,
      mobileView: true,
      type: 'data',
    },
    {
      title: this.translateService.instant('Register.lastname'),
      attr: 'lastname',
      view: true,
      mobileView: true,
      type: 'data',
    },
    {
      title: '@' + this.translateService.instant('Register.nickname'),
      attr: 'nickname',
      view: true,
      mobileView: true,
      type: 'data',
    },
    {
      title: 'Email',
      attr: 'email',
      view: true,
      mobileView: false,
      type: 'data',
    },
    {
      title: this.translateService.instant('Register.secondEmail'),
      attr: 'email2',
      view: false,
      mobileView: false,
      type: 'data',
    },
    {
      title: this.translateService.instant('Register.phone1'),
      attr: 'phone1',
      view: false,
      mobileView: false,
      type: 'data',
    },
    {
      title: this.translateService.instant('Register.phone2'),
      attr: 'phone2',
      view: false,
      mobileView: false,
      type: 'data',
    },
    {
      title: this.translateService.instant('Company.department'),
      attr: 'title',
      view: true,
      mobileView: false,
      type: 'data',
    },
    {
      title: this.translateService.instant('Company.location'),
      attr: 'location',
      view: false,
      mobileView: false,
      type: 'data',
    },
    {
      title: this.translateService.instant('Generic.created'),
      attr: 'created_at',
      view: true,
      mobileView: false,
      type: 'date',
    },
    {
      title: this.translateService.instant('Register.permissions'),
      attr: 'permitionBulkAction',
      view: false,
      mobileView: false,
      type: 'bulk_action',
      icon: 'admin_panel_settings',
      action: 'permition_bulk_action',
      color: 'primary',
    },
  ];

  ngOnInit(): void {
    let companyIdFromRouter = this.route.snapshot.paramMap.get('companyId');
    if (companyIdFromRouter) {
      this.userFilters.companyId = +companyIdFromRouter;
    } else {
      this.userFilters.companyId = this.companyIdToOpen;
    }
    this.getDepartments(this.userFilters.companyId);

    this.getUsers();
  }

  refreshUsers(filter: UsersFilter) {
    this.userFilters = filter;
    this.getUsers();
  }

  public getUsers() {
    this.userSerice
      .getUsers(this.userFilters)
      .pipe(take(1))
      .subscribe((resUsers) => {
        this.users = resUsers;
      });
  }

  private getDepartments(companyId) {
    if (companyId) {
      this.companyService
        .getCompanyById(companyId)
        .pipe(take(1))
        .subscribe((res) => {
          this.departments = res.departments;
        });
    } else {
      this.companyService
        .getCompanyForUser()
        .pipe(take(1))
        .subscribe((res) => {
          this.departments = res.departments;
        });
    }
  }

  public onSelectUser(user: User) {
    this.router.navigate(['localadmin/user', { id: user.id }]);
  }

  public newUser() {
    let params = {};
    if (this.userFilters.companyId) {
      params = { companyId: this.userFilters.companyId };
    }
    this.router.navigate(['localadmin/user', params]);
  }

  public goBack() {
    this.__location.back();
  }

  public goToPath(path: string) {
    this.router.navigate([path]);
  }

  public onBulkActionPress(data) {
    console.log(data);
    if (data.action == 'permition_bulk_action') {
      this.openDialgForBulkPermitions(data.items);
    }
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  private openDialgForBulkPermitions(selectedUsers) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {};

    const dialogRef = this.dialog.open(
      PermitionBulkAssignComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        console.log(result);
        this.userSerice
          .bulkUpdatePermissions(selectedUsers, result)
          .pipe(take(1))
          .subscribe((res) => {
            if (res.success) {
              this.notificationService.showSnackbarMessage(
                'Messages.successfulSave'
              );
            } else {
              this.notificationService.showSnackbarMessage('Messages.failSave');
            }
          });
      }
    });
  }
}
