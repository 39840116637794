import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DataListComponent } from '../data-list/data-list.component';
import { Location } from '@angular/common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdditionalBtn } from 'src/app/model';

@Component({
  selector: 'app-data-list-with-filters',
  templateUrl: './data-list-with-filters.component.html',
  styleUrls: ['./data-list-with-filters.component.css'],
})
export class DataListWithFiltersComponent implements OnInit {
  @Input() entity_code: string;
  @Input() ADDITEMBUTTONS: AdditionalBtn[];
  @Output() ADDITIONALITEMBTNACTION = new EventEmitter<AdditionalBtn>();
  public filterMode: boolean = true;
  public showItem: boolean = false;
  public selectedItem: string;

  @ViewChild(DataListComponent) listComp: DataListComponent;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private __location: Location
  ) {}

  ngOnInit(): void {}
  selectItem(entityItem: any) {
    this.selectedItem = entityItem.id;
    this.showItem = true;
  }

  public closeItem() {
    this.showItem = false;
  }

  public goBack() {
    this.__location.back();
  }
  public search() {
    this.listComp.entityFilters.onlyFollow = 0;
    this.listComp.entityFilters.currentPage = 1;
    this.listComp.refreshEntityList(this.listComp.entityFilters);
  }

  public getOnlyFollow() {
    this.listComp.entityFilters.onlyFollow = 1;
    this.listComp.refreshEntityList(this.listComp.entityFilters);
  }

  public newItem() {
    this.selectedItem = 'new';
    this.showItem = true;
  }

  public clearAllfilters() {
    this.listComp.filtersComp.clearAllfilters();
  }

  public additionalItemAction(ev: AdditionalBtn) {
    this.ADDITIONALITEMBTNACTION.emit(ev);
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));
}
