import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Approval } from 'src/app/models/approval';
import { ApprovalItemDiagComponent } from 'src/app/modules/approval/approval-item-diag/approval-item-diag.component';
import { ApprovalNewComponent } from 'src/app/modules/approval/approval-new/approval-new.component';
import { ApprovalService } from 'src/app/services/approval.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-data-item-approvals',
  templateUrl: './data-item-approvals.component.html',
  styleUrls: ['./data-item-approvals.component.css'],
})
export class DataItemApprovalsComponent {
  public approvals: Approval[] = [];
  @Input() ITEMID: any;
  @Input() ENTITYCODE: string;

  constructor(
    private approvalService: ApprovalService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnChanges(): void {
    this.GetApprovals();
  }

  private GetApprovals() {
    this.approvalService
      .getApprovalsForItem(this.ITEMID)
      .pipe(take(1))
      .subscribe((res) => {
        this.approvals = res;
      });
  }

  public onSelectApproval(approval) {
    this.open_approval_diag(approval);
  }

  public add_approval_diag() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      entityCode: this.ENTITYCODE,
      itemId: this.ITEMID,
    };

    const dialogRef = this.dialog.open(ApprovalNewComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      this.GetApprovals();
    });
  }

  public open_approval_diag(approval: Approval) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      approvalId: approval.id,
    };

    const dialogRef = this.dialog.open(ApprovalItemDiagComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.GetApprovals();
      }
    });
  }
}
