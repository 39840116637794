import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpinnerType } from 'src/app/common/enums';
import { BaseFilter } from 'src/app/common/filters';
import { FilterHelper } from 'src/app/common/helper';
import { PagedData, ReturnData } from 'src/app/model';
import { ERPMoneyaccount } from 'src/app/models/erpmoneyaccount';
import { ERPMoneydoc } from 'src/app/models/erpmoneydoc';
import { BaseService } from '../base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErpMoneyaccountService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getActiveMoneyaccounts(): Observable<ERPMoneyaccount[]> {
    return this.get<ERPMoneyaccount[]>(this.apiUrl + 'erp/moneyaccounts');
  }

  public getMoneyaccountsTree(): Observable<ERPMoneyaccount> {
    return this.get<ERPMoneyaccount>(this.apiUrl + 'erp/moneyaccountstree');
  }

  public storeMoneyaccount(
    moneyaccount: ERPMoneyaccount
  ): Observable<ReturnData<ERPMoneyaccount>> {
    return this.post<ReturnData<ERPMoneyaccount>>(
      this.apiUrl + 'erp/moneyaccounts',
      moneyaccount
    );
  }

  public updateMoneyAccount(
    moneyaccount: ERPMoneyaccount
  ): Observable<ReturnData<ERPMoneyaccount>> {
    return this.put<ReturnData<ERPMoneyaccount>>(
      this.apiUrl + 'erp/moneyaccounts/' + moneyaccount.id,
      moneyaccount
    );
  }

  public getAllMoneyaccounts(
    filters: BaseFilter
  ): Observable<ERPMoneyaccount[]> {
    let queryString = FilterHelper.QueryStringHelper(filters);
    return this.get<ERPMoneyaccount[]>(
      this.apiUrl + 'erp/moneyaccounts_all' + queryString
    );
  }

  public getSingleMoneyaccount(id: number): Observable<ERPMoneyaccount> {
    return this.get<ERPMoneyaccount>(this.apiUrl + 'erp/moneyaccounts/' + id);
  }
}
