<mat-dialog-content class="mat-typography">
  <div class="buttonPanel mat-elevation-z3">
    <button mat-button (click)="goBack()">
      <mat-icon>keyboard_backspace</mat-icon>
      <span *ngIf="!(isHandset$ | async)">{{
        'Generic.back' | translate
      }}</span>
    </button>
    <mat-divider [vertical]="true"></mat-divider>

    <button mat-button (click)="attachfileInput.click()">
      <mat-icon>attachment</mat-icon>
      <span *ngIf="!(isHandset$ | async)">{{
        'Generic.openFile' | translate
      }}</span>
    </button>
    <mat-divider [vertical]="true"></mat-divider>
  </div>
  <input
    hidden
    (change)="fileChangeEvent($event)"
    #attachfileInput
    type="file"
    name="file"
    accept="image/*"
  />
  <div class="webcamDiv">
    <webcam
      [height]="(isHandset$ | async) ? 200 : 600"
      [width]="(isHandset$ | async) ? 200 : 750"
      [trigger]="triggerObservable"
      (imageCapture)="handleImage($event)"
    ></webcam>
  </div>
  <button
    mat-fab
    color="primary"
    (click)="triggerSnapshot()"
    class="takepictureBtn"
  >
    <mat-icon>camera</mat-icon>
  </button>
  <!--


      <div class="snapshot" *ngIf="webcamImage">
    <h2>Here is your image!</h2>
    <img [src]="webcamImage.imageAsDataUrl"/>
  </div>
-->
</mat-dialog-content>
