import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataItemDiagComponent } from '../common/entity/data-item-diag/data-item-diag.component';
import { EntityService } from '../services/entity.service';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[ItemLink]',
})
export class ItemLinkDirective implements AfterViewInit {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private entityService: EntityService,
    public dialog: MatDialog
  ) {}

  ngAfterViewInit() {
    var myRegexp = /#([0-9]+)/g;
    var match = myRegexp.exec(this.el.nativeElement.innerText);

    if (match) {
      let textArr = this.el.nativeElement.innerText.split(match[0]);

      let firstSpan = this.renderer.createElement('span');
      let firstTxt = this.renderer.createText(textArr[0] + ' ');
      this.renderer.appendChild(firstSpan, firstTxt);

      let linkSpan = this.renderer.createElement('span');
      this.renderer.setStyle(linkSpan, 'color', '#2a7189');
      this.renderer.setStyle(linkSpan, 'cursor', 'pointer');

      this.renderer.setStyle(linkSpan, 'text-decoration', 'underline');
      let linkTxt = this.renderer.createText(match[0]);

      this.renderer.listen(linkSpan, 'click', (event) => {
        this.entityService
          .getItemEntity(match[1])
          .pipe(take(1))
          .subscribe((res) => {
            this.previewItem(match[1], res.entity_code);
          });
      });
      this.renderer.appendChild(linkSpan, linkTxt);

      let secondSpan = this.renderer.createElement('span');
      let secondTxt = this.renderer.createText(' ' + textArr[1]);
      this.renderer.appendChild(secondSpan, secondTxt);

      const childElements = this.el.nativeElement.children;
      for (let child of childElements) {
        this.renderer.removeChild(this.el.nativeElement, child);
      }
      this.renderer.setValue(this.el.nativeElement, '');
      this.renderer.appendChild(this.el.nativeElement, firstSpan);
      this.renderer.appendChild(this.el.nativeElement, linkSpan);
      this.renderer.appendChild(this.el.nativeElement, secondSpan);
    }
  }

  public previewItem(itemId: string, entcode: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      itemId: itemId,
      entityCode: entcode,
    };
    this.dialog.open(DataItemDiagComponent, dialogConfig);
  }
}
