import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseFilter } from 'src/app/common/filters';
import { FilterHelper } from 'src/app/common/helper';
import { ReturnData } from 'src/app/model';
import { ERPSpdoctype } from 'src/app/models/erpspdoctype';
import { BaseService } from '../base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErpSpdoctypeService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getActiveSpdoctypes(): Observable<ERPSpdoctype[]> {
    return this.get<ERPSpdoctype[]>(this.apiUrl + 'erp/spdoctypes');
  }

  public storeSpdoctype(
    warehoust: ERPSpdoctype
  ): Observable<ReturnData<ERPSpdoctype>> {
    return this.post<ReturnData<ERPSpdoctype>>(
      this.apiUrl + 'erp/spdoctypes',
      warehoust
    );
  }

  public updateSpdoctype(
    warehoust: ERPSpdoctype
  ): Observable<ReturnData<ERPSpdoctype>> {
    return this.put<ReturnData<ERPSpdoctype>>(
      this.apiUrl + 'erp/spdoctypes/' + warehoust.id,
      warehoust
    );
  }

  public getAllSpdoctypes(filters: BaseFilter): Observable<ERPSpdoctype[]> {
    let queryString = FilterHelper.QueryStringHelper(filters);
    return this.get<ERPSpdoctype[]>(
      this.apiUrl + 'erp/spdoctypes_all' + queryString
    );
  }

  public getSingleSpdoctype(id: number): Observable<ERPSpdoctype> {
    return this.get<ERPSpdoctype>(this.apiUrl + 'erp/spdoctypes/' + id);
  }
}
