import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { EntityMenuSelectorComponent } from 'src/app/common/entity-menu-selector/entity-menu-selector.component';
import { EntitySelectorDiagComponent } from 'src/app/common/entity-selector-diag/entity-selector-diag.component';
import { Approval, Assignee } from 'src/app/models/approval';
import { ApprovalService } from 'src/app/services/approval.service';
import { EntityService } from 'src/app/services/entity.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-approval-new',
  templateUrl: './approval-new.component.html',
  styleUrls: ['./approval-new.component.css'],
})
export class ApprovalNewComponent implements OnInit {
  public approval: Approval;
  public itemLabel: string = '';

  @ViewChild('addlinkMenu') entityMenuSelectorLink: EntityMenuSelectorComponent;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<ApprovalNewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private approvalSerivce: ApprovalService,
    private notificationService: NotificationService,
    private entityService: EntityService
  ) {}

  ngOnInit(): void {
    this.approval = new Approval();

    if (this.data.itemId && this.data.entityCode) {
      this.getItem(this.data.entityCode, this.data.itemId);
    }
  }

  public save() {
    this.approvalSerivce
      .addApproval(this.approval)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.success) {
          this.notificationService.showSnackbarMessage(
            'Messages.successfulSave'
          );
          this.dialogRef.close(res.data);
        } else {
          this.notificationService.showSnackbarMessage('Messages.failSave');
        }
      });
  }

  public selectUserDiag() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      entityCode: 'user',
      // entity_id: this.entity.id
    };

    const dialogRef = this.dialog.open(
      EntitySelectorDiagComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        this.approval.assignees = [];
        let newAssignee: Assignee = new Assignee();
        newAssignee.assignee = result;
        newAssignee.assignee_id = result.id;
        this.approval.assignees.push(newAssignee);
      }
    });
  }

  public selectEntityIDiag(entity_code: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      entityCode: entity_code,
    };

    const dialogRef = this.dialog.open(
      EntitySelectorDiagComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      this.getItem(entity_code, result.id);
    });
  }

  private createItemLabel(item) {
    let label: string = '';

    console.log(item);

    for (let attr of item.fields) {
      label += attr.field.mobilePreview == '1' ? attr.value + ' ' : '';
    }

    return label;
  }

  private getItem(entity_code: string, itemId: string) {
    this.entityService
      .geEntityItem(entity_code, itemId)
      .pipe(take(1))
      .subscribe((resItem) => {
        this.approval.entdata_id = resItem.id;
        this.itemLabel = this.createItemLabel(resItem);
      });
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));
}
