import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { InstallationLine } from 'src/app/models/installationline';
import { InstallationService } from 'src/app/services/installations.service';
import { LayoutService } from '../../../../../services/layout.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-installation-list',
  templateUrl: './installation-list.component.html',
  styleUrls: ['./installation-list.component.css'],
})
export class InstallationListComponent implements OnInit {
  public lines: InstallationLine[] = [];
  selectedColumns: string[] = ['id', 'installation_name', 'hours', 'del'];
  @ViewChild(MatTable) table: MatTable<any>;

  constructor(
    private installationService: InstallationService,
    public layoutService: LayoutService
  ) {}

  @Input() ITEM: any;
  @Input() ITEMID: any;
  @Input() disabled: boolean = false;
  @Input() allAttr: any;

  ngOnInit(): void {
    this.ITEM.installationslines = [];
  }

  ngAfterViewInit(): void {
    if (this.ITEMID != 'new') {
      this.getSavedLines(this.ITEMID);
    } else {
      this.addItemLine(null);
    }
  }

  private getSavedLines(entdataId: string) {
    this.installationService
      .getSavedLines(entdataId)
      .pipe(take(1))
      .subscribe((res) => {
        this.ITEM.installationslines = res;
        this.lines = res;
        this.addItemLine(null);
        this.refresh();
      });
  }

  public removeItemLine(index: number) {
    this.lines.splice(index, 1);
    this.refresh();
  }

  public fillInstallationlineItem(item: any, line: InstallationLine) {
    console.log(item);
    line.installation = item;
    line.installation_id = item.id;
    this.addItemLine(null);
  }

  public refresh() {
    this.table.renderRows();
    this.ITEM.installationslines = this.lines.filter((x) => x.installation);
  }

  public addItemLine(item: any) {
    let newLine: InstallationLine = new InstallationLine();
    newLine.installation = item;
    newLine.hours = 1;
    newLine.installation_id = item?.id ?? null;
    this.lines.push(newLine);
    this.refresh();
  }
}
