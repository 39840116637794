import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ElementDataService {
  private extractedData: any;

  setExtractedData(data: any) {
    this.extractedData = data;
  }

  getExtractedData() {
    return this.extractedData;
  }
}
