<mat-card class="companyChartCard">
  <mat-card-header>
    <mat-card-title> {{ 'Company.structure' | translate }} </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-table [dataSource]="departments" class="mat-elevation-z1">
      <!-- Position Column -->
      <ng-container matColumnDef="id">
        <mat-header-cell
          style="color: black; font-weight: bold"
          *matHeaderCellDef
        >
          #
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="title">
        <mat-header-cell
          style="color: black; font-weight: bold"
          *matHeaderCellDef
        >
          {{ 'Company.title' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.title }} </mat-cell>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="location">
        <mat-header-cell
          style="color: black; font-weight: bold"
          *matHeaderCellDef
        >
          {{ 'Company.location' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.location }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="parent">
        <mat-header-cell
          style="color: black; font-weight: bold"
          *matHeaderCellDef
        >
          {{ 'Company.parentDep' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span *ngIf="element.parent_department">{{
            element.parent_department.title
          }}</span>
        </mat-cell>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="action">
        <mat-header-cell
          style="color: black; font-weight: bold; margin-left: 3px"
          *matHeaderCellDef
          >{{ 'Company.actions' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            color="warn"
            matTooltip="{{ 'Company.deleteDepartment' | translate }}"
            (click)="delete(element)"
          >
            <mat-icon>delete_outline</mat-icon>
          </button>
          <button
            mat-icon-button
            color="primary"
            matTooltip="{{ 'Company.editDepartment' | translate }}"
            (click)="edit(element)"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </mat-card-content>
</mat-card>
