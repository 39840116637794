import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ScheduleFilter } from '../common/filters';
import { ScheduleDto } from '../model';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductionscheduleService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getProdOrdersByScheduleId(
    filters: ScheduleFilter
  ): Observable<ScheduleDto> {
    return this.post<ScheduleDto>(
      this.apiUrl + 'productionschedule/orders/' + filters.schedule_id,
      filters
    );
  }
}
