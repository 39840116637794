import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataItemDiagComponent } from '../common/entity/data-item-diag/data-item-diag.component';
import { Entdata } from '../model';
import { EntityService } from './entity.service';
import { TransformationService } from './transformation.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AfterSaveEnityItemService {
  constructor(
    private tranformationService: TransformationService,
    private dialog: MatDialog,
    private entityService: EntityService
  ) {}

  public specialLogicAfterSave(entityToOpen: string, currentEntItem: Entdata) {
    if (entityToOpen == 'quote') {
      let fld = currentEntItem.data.find(
        (x) => x.fld_code_ent == 'quote_status'
      );

      if (fld && fld.value == 'Close as revised') {
        this.entityService
          .copyItem(entityToOpen, currentEntItem.id + '', 1)
          .pipe(take(1))
          .subscribe((res) => {
            if (res.data.length > 0) {
              this.previewLinkItem(res.data[0], 'quote');
            }
          });
        // this.router.navigate(['/quoteconf', { id: currentEntItem.id }]);
      } else if (fld && fld.value == 'Close as won') {
        this.transformQuoteToOrder(currentEntItem.id);
      }
    } else if (entityToOpen == 'lead') {
      let fld = currentEntItem.data.find(
        (x) => x.fld_code_ent == 'lead_status'
      );
      if (fld && fld.value == 'Close as won') {
        this.transformLeadToAccount(currentEntItem.id);
      }
    }
  }

  private transformQuoteToOrder(itemIdToOpen: number) {
    this.tranformationService
      .quoteToOrder(itemIdToOpen)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.success) {
          this.previewLinkItem(res.data, 'order');
        }
      });
  }

  private transformLeadToAccount(itemIdToOpen: number) {
    this.tranformationService
      .leadToAccount(itemIdToOpen)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.success) {
          this.previewLinkItem(res.data, 'account');
        }
      });
  }

  public previewLinkItem(item: any, entityCode: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      itemId: item.id,
      entityCode: entityCode,
    };
    this.dialog.open(DataItemDiagComponent, dialogConfig);
  }
}
