import { ReturnData, Setting } from '../model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SettingsService extends BaseService {
  public settings: Setting[] = [];
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public saveSetting(setting: Setting): Observable<ReturnData<any>> {
    return this.post<ReturnData<any>>(this.apiUrl + 'settings', setting);
  }

  public getSettings(key: string): Observable<Setting[]> {
    return this.get<Setting[]>(this.apiUrl + 'settings/' + key);
  }

  public getMrpErpSettings(key: string): Observable<Setting[]> {
    return this.get<Setting[]>(this.apiUrl + 'erpsettings/' + key);
  }

  public getSettingsForAdmin(): Observable<Setting[]> {
    return this.get<Setting[]>(this.apiUrl + 'admin/settings');
  }
  public saveSettingsForAdmin(settings: Setting[]): Observable<Setting[]> {
    return this.post<Setting[]>(this.apiUrl + 'admin/settings', settings);
  }

  public async getAllSettings() {
    this.get<Setting[]>(this.apiUrl + 'settings')
      .pipe(take(1))
      .subscribe((response) => (this.settings = response));
  }

  public getSavedSettings(key: string): Setting[] {
    return this.settings.filter((x) => x.key == key);
  }

  public delSettingByKey(key: string): Observable<ReturnData<any>> {
    return this.delete<ReturnData<any>>(this.apiUrl + 'settings/' + key);
  }

  public delSettingByKeyValue(
    key: string,
    value: string
  ): Observable<ReturnData<any>> {
    return this.delete<ReturnData<any>>(
      this.apiUrl + 'settings/' + key + '/' + value
    );
  }
}
