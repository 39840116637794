<mat-form-field>
  <mat-label>{{ 'ERPMoneyaccounts.dest_acc' | translate }}</mat-label>

  <mat-select [(ngModel)]="moneyDoc.to_acc_id">
    <mat-option
      *ngFor="let moneyaccount of moneyaccount"
      [value]="moneyaccount.id"
    >
      {{ moneyaccount.id }} - {{ moneyaccount.title }}
    </mat-option>
  </mat-select>
</mat-form-field>
