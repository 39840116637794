import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChartData, StatisticsReport } from '../model';
import { take } from 'rxjs/operators';

export const availableFldTypes: string[] = [
  'number',
  'dropdown',
  'user',
  'singleEntity',
];
export const availableEntitiesForStatusChange = [
  'tasks',
  'lead',
  'order',
  'service',
  'quote',
  'productionorder',
  'purchasingorder',
  'hrticket',
  'breakdown',
  'repair_order',
];
export const availableFunctions: { code: string; description: string }[] = [
  { code: 'count', description: 'Statistics.count' },
  { code: 'average', description: 'Statistics.average' },
  { code: 'sum', description: 'Statistics.sum' },
];
export const dateDropdownOptions: { type: string; message: string }[] = [
  { type: 'choose', message: 'Επιλέξτε Διάστημα' },
  { type: 'current_month', message: 'Τρέχων Μήνας' },
  { type: 'previous_month', message: 'Προηγούμενος Μήνας' },
];

@Injectable({
  providedIn: 'root',
})
export class StatisticsService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  savedCharts = new BehaviorSubject<ChartData[]>([]);
  savedReports = new BehaviorSubject<StatisticsReport[]>([]);
  chartsForReport = new BehaviorSubject<ChartData[]>([]);

  public getFieldStatistics(
    entfieldId: number,
    selected_function: string,
    controlField: string,
    creation_from: string,
    creation_to: string,
    status_change_from: string,
    status_change_to: string
  ): Observable<any> {
    return this.post<any>(this.apiUrl + 'statistics/entfieldstatistics', {
      entfield_id: entfieldId,
      selected_function: selected_function,
      control_field: controlField,
      creation_from: creation_from,
      creation_to: creation_to,
      status_change_from: status_change_from,
      status_change_to: status_change_to,
    });
  }

  public getSavedCharts(): void {
    this.get<ChartData[]>(this.apiUrl + 'statistics/savedcharts')
      .pipe(take(1))
      .subscribe((res) => {
        this.savedCharts.next(res);
      });
  }

  public saveChart(chartData: ChartData): Observable<any> {
    return this.post<any>(this.apiUrl + 'statistics/savechart', chartData);
  }

  public updateChart(chartId: number, chartData: ChartData): Observable<any> {
    return this.post<any>(
      this.apiUrl + `statistics/updatechart/${chartId}`,
      chartData
    );
  }

  public deleteSavedChart(chartId: number): Observable<any> {
    return this.delete<any>(
      this.apiUrl + 'statistics/deleteSavedChart/' + chartId
    );
  }

  public getSavedReports(): void {
    this.get<StatisticsReport[]>(this.apiUrl + 'statistics/reports')
      .pipe(take(1))
      .subscribe((res) => {
        this.savedReports.next(res ?? []);
      });
  }

  public saveReport(newReport: StatisticsReport): Observable<any> {
    return this.post<any>(this.apiUrl + 'statistics/savereport', newReport);
  }

  public updateReport(
    reportId: number,
    updatedReport: StatisticsReport
  ): Observable<any> {
    return this.post<any>(
      this.apiUrl + `statistics/updatereport/${reportId}`,
      updatedReport
    );
  }

  public deleteReport(reportId: number): Observable<any> {
    return this.delete<any>(
      this.apiUrl + 'statistics/deletereport/' + reportId
    );
  }
}
