import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '../model';

@Injectable({
  providedIn: 'root',
})
export class LoginService extends BaseService {
  private helper = new JwtHelperService();

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public login(formData: FormData) {
    return this.post<any>(this.apiUrl + 'login', formData);
  }

  public hasScopePermission(scope: string): boolean {
    let token = localStorage.getItem('token');
    let scoprArr = this.helper.decodeToken(token).scopes;

    let authScopes = scope.split(',');

    let flag = false;
    for (let authscope of authScopes) {
      if (scoprArr.indexOf(authscope) >= 0) {
        return true;
        let flag = true;
        break;
      }
    }

    return flag;
  }

  public getLoginUser(): any {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  public setLoginUser(user: User): any {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  public setSession(token: string, user: User) {
    localStorage.setItem('token', token);
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  public getToken(): any {
    return localStorage.getItem('token');
  }

  public logout(): any {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.removeItem('tabIndex');
    localStorage.removeItem('searchFilterPrefs');
    window.location.href = '/login';
  }
}
