<mat-form-field style="margin-right: 10px">
  <mat-label>Από</mat-label>
  <input matInput [matDatepicker]="dp" [formControl]="date" />
  <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
  <mat-datepicker
    #dp
    startView="multi-year"
    (monthSelected)="setMonthAndYear($event, dp)"
    panelClass="example-month-picker"
  >
  </mat-datepicker>
</mat-form-field>
