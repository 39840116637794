import {
  ChannelReaction,
  FinancialType,
  MeasurementUnit,
  ReportType,
} from 'src/app/common/enums';
import { MatTableDataSource } from '@angular/material/table';
import { WorkflowTemplateStatusEnum } from './enums/WorkflowTemplateStatus.enum';
import { WorkflowStatusEnum } from './enums/WorkflowStatus.enum';
import { WorkflowTemplateStepTypeEnum } from './enums/WorkflowTemplateStepType.enum';

export class ReturnData<T> {
  success: boolean;
  data: T;
  error: string;
}

export class Company {
  id: number;
  name: string;
  expire: Date;
  department_tree: Department;
  departments: Department[];

  discount: number;
  users_count: number;
  deleted_at: Date;
  created_at: Date;
  updated_at: Date;
}

export class Department {
  id: number;
  company: Company;
  company_id: number;
  sub_departments: Department[];
  parent_department: Department;
  department_id: number;
  title: string;
  location: string;
  users_count: number;
  deleted_at: Date;
  created_at: Date;
  updated_at: Date;
}
export class Invitation {
  id: number;
  email: string;
  company_id: number;
  token: string;
  expire: Date;
  company: Company;
  deleted_at: Date;
  created_at: Date;
  updated_at: Date;
}

export class Module {
  id: number;
  name: string;
  name_gr: string;
  price: number;
  checked: boolean = false;
  path: string;
  scope: string;
  maticon: string;
  section: string;
  title?: string;
}

export class User {
  id: number;
  password: string;
  cpassword: string;
  firstname: string;
  lastname: string;
  birthdate: Date;
  email: string;
  email2: string;
  phone1: string;
  phone2: string;
  nickname: string;
  lang: string;
  photoBinary: any;
  photoPath: string;
  department_id: number;
  permissions: Permission[] = [];
  costs: any[];
  company_id: number;
  deleted_at: Date;
  created_at: Date;
  updated_at: Date;
  isSalesman: boolean;
}

export class ChatUser extends User {
  lastmsg: string;
  lastmsgTime: Date;
  unreadchats_count: number;
}
export class UserJoinDepartment extends User {
  location: string;
  title: string;
}

export class PagedData<T> {
  constructor() {
    this.current_page = 0;
    this.total = 1;
    this.data = [];
  }
  current_page: number;
  data: T[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: string;
  to: number;
  total: number;
}

export class Permission {
  id: number;
  user_id: number;
  module_id: number;
  module: Module;
  startDate: Date;
  endDate: Date;
  deleted_at: Date;
  created_at: Date;
  updated_at: Date;
}

export class CompanyReport {
  company: Company;
  users: User[];
  totalCost: number;
}

export class Notify {
  created_at: Date;
  data: NotifData;
  id: string;
  notifiable_id: number;
  notifiable_type: string;
  read_at: Date;
  type: string;
  updated_at: Date;
}

export class NotifData {
  message: string;
  entdata: number;
  entity_code: string;
  non_entity_type: string;
  non_entity_id: number;
  entityChoice?: string;
  workflowStepId?: number;
  type: string;
}

export class Question {
  id: number;
  message: string;
  from_id: number;
  to_id: number;
  entdata_id: number;
  entfield_id: number;
  answerd_at: Date;

  sender: User;
  item: Entdata;
  field: Field;
}

export class ChatMsg {
  id: number;
  user_from: number;
  user_to: number;
  message: string;
  isRead: number;
  created_at: Date;
  updated_at: Date;
}

export class Entity {
  id: number;
  entity_code: string;
  name_gr: string;
  fields: Field[];
  name_en: string;
  company_id: number;
  isCore: number;
  icon: string;
  isActive: number;
  isActiveB: boolean;
  secutiyIsActive: number;
  secutiyIsActiveB: boolean;
  created_at: Date;
  updated_at: Date;
  security: EntitySecurity;
}

export class EntitySecurity {
  id: number;
  entity_id: number;
  user_access_fld_id: number;
  department_access_fld_id: number;
  always_active: boolean;
  client_security: boolean;
  is_active: boolean;
}

export class FieldType {
  id: number;
  fld_code: string;
  name_gr: string;
  name_en: string;
  created_at: Date;
  updated_at: Date;
}

export class Entdata {
  id: number;
  entity_id: number;
  isActive: number;
  data: any[];
  follow: boolean;
  owner: User;
  fields: Entdatadetail[];
  owner_id: number;
  created_at: Date;
  updated_at: Date;
}

export class ListEntdata {
  id: number;
  created_at: Date;
  fields: ListField[];
}

export class ListField {
  fieldCode: string;
  value: string;
}

export class Entdatadetail {
  id: number;
  entity_id: number;
  entdata_id: number;
  entfield_id: number;
  value: string;
  field: Field;
  cols: number;
  rows: number;
  created_at: Date;
  updated_at: Date;
}

export class Field {
  id: number;
  entity_id: number;
  fld_code: string;
  label_gr: string;
  label_en: string;
  entfieldtype_id: number;
  for_entity: string;
  isCore: number;
  gridPreview: number;
  mobilePreview: number;
  hiddeOnFilters: number;
  isGridColumn: number;
  isMandatory: number;
  editableByOwner: number;
  rows: number;
  cols: number;
  orderId: number;
  search_from: string;
  search_to: string;
  field_specs: FieldType;
  created_at: Date;
  updated_at: Date;
}

export class DocumentFile {
  id: number;
  path: string;
  filename: string;
  entdata_id: number;
  created_at: Date;
  updated_at: Date;
}

export class Post {
  id: number;
  entdata_id: number;
  company_id: number;
  user_id: number;
  text: string;
  user: User;
  created_at: Date;
  updated_at: Date;
}

export class Entconnection {
  id: number;
  entdata_id: number;
  with_entdata_ids: number[];
  created_at: Date;
  updated_at: Date;
}

export class Taskgroup {
  id: number;
  entdata_id: number;
  company_id: number;
  user_id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
}

export class Team {
  id: number;
  users: User[];
  entdata_id: number;
  entfield_id: number;
  created_at: Date;
  updated_at: Date;
}

export class ProjectDto extends Entdata {
  sprints: Sprint[];
}
export class ScheduleDto extends Entdata {
  sprints: Sprint[];
}
export class Sprint {
  id: number;
  orders: any[];
  tasks: Entdata[];
  tasksDto: TaskDto[];
  company_id: number;
  project_id: number;
  name: string;
  showSprint: boolean;
  created_at: Date;
  updated_at: Date;
}

export class TaskDto {
  id: number;
  task_assignee: any;
  task_startdate: Date;
  task_enddate: Date;
  task_duration: string;
  task_group: Taskgroup;
  task_status: string;
  task_desc: string;
  sprint: string;
  task_childtask: number;
  completed_at: Date;
  created_at: Date;
  updated_at: Date;
}

export class SavedFilter {
  id: number;
  title: string;
  filters: SavedFilterValues[];
  connectedWith: number;
  trashed: number;
  itemId: number;
  ownerId: number;
  createdAtSearchDate: Date;
  strValue: string;
  entity_code: string;
  created_at: Date;
  updated_at: Date;
}

export class SavedFilterValues {
  field_code: string;
  search_from: string;
  search_to: string;
}

export interface IBreadCrumb {
  label: string;
  url: string;
}

export class SearchEntResults {
  find_counter: number;
  entity_id: number;
  entity: Entity;
}

export class ChatPopup {
  constructor() {
    this.isChatMinimized = false;
  }
  userForPopup: User;
  isChatMinimized: boolean;
}

export class ConfQuote {
  constructor() {
    this.quotelines = [];
    this.totalAmount = 0;
    this.totalDiscount = 0;
  }

  id: number;

  totalDiscount: number;

  quotelines: ConfQuoteLine[];
  totalAmount: number;

  created_at: Date;
  updated_at: Date;
}

export class ConfQuoteLine {
  id: number;
  conf_quote_id: number;
  product_id: number;
  product: any;
  qty: number;
  discount: number;
  discount2: number;
  unit: MeasurementUnit;
  availability: number;
  numFld1: number;
  numFld2: number;
  numFld3: number;
  strFld1: string;
  strFld2: string;
  strFld3: string;
  drpDownFld1: string;
  drpDownFld2: string;
  drpDownFld3: string;
  aa: number;
  vat: number;
  price: number;
  warehouse_id: number;
  created_at: Date;
  updated_at: Date;
  containers?: number;
}

export class Expense {
  constructor() {
    this.expenseLines = [];
    this.totalAmount = 0;
    this.totalDiscount = 0;
  }

  id: number;
  totalDiscount: number;
  expenseLines: ExpenseLine[];
  totalAmount: number;
  created_at: Date;
  updated_at: Date;
}

export class ExpenseLine {
  id?: number;
  expense_id: number;
  entdata_id: number;
  entfield_id: number;
  amount: number;
  date: Date;
  pay_method: string;
  created_at: Date;
  updated_at: Date;
  numFld1: number;
  numFld2: number;
  numFld3: number;
  strFld1: string;
  strFld2: string;
  strFld3: string;
  drpDownFld1: string;
  drpDownFld2: string;
  drpDownFld3: string;
  expense?: any;
}

export class SemiProductCategory {
  constructor() {
    this.semiproducts = [];
    this.seletedQty = 1;
    this.selectedSemiProd = [];
  }
  id: number;
  company_id: number;
  name: string;
  semiproducts: SemiProduct[];
  order: number;
  selectedSemiProd: SemiProduct[] = [];
  seletedQty: number;
  created_at: Date;
  updated_at: Date;
}

export class SemiProduct {
  constructor() {
    this.compatibilities = [];
  }
  id: number;
  company_id: number;
  semi_product_category_id: number;
  product_id: number;
  name: string;
  code: string;
  isActive: number;
  isActiveB: boolean;
  compatibilities: any[];
  extraFld1: string;
  extraFld2: string;
  extraFld3: string;
  qty: number;
  unit: MeasurementUnit;
  checked: boolean;
  created_at: Date;
  updated_at: Date;
}

export class NotesTableLine {
  id: number;
  entdatadetail_id: number;
  column_1: string;
  column_2: string;
  column_3: string;
  column_4: string;
  column_5: string;
  column_6: string;
  column_7: string;
  column_8: string;
  column_9: string;
  column_10: string;
  created_at: Date;
  updated_at: Date;
}

export class OpportunityStep {
  title: string;
  startIndex: number;
  endIndex: number;
  isCompleted: boolean;
  label: any;
}

export class ItemTab {
  title: string;
  startIndex: number;
  endIndex: number;
  tabFld: any;
  activeStep: boolean;
}

export class Forcast {
  id: number;
  title: string;
}

export class Report {
  title: string;
  report_view_path: string;
  report_pdf_path: string;
}

export class ReportFinancial {
  constructor(type: ReportType, report: FinancialType, year: number) {
    this.type = type;
    this.report = report;
    this.year = year;
    this.id = 0;
    this.jan = 0;
    this.feb = 0;
    this.mar = 0;
    this.apr = 0;
    this.may = 0;
    this.jun = 0;
    this.jul = 0;
    this.aug = 0;
    this.sep = 0;
    this.oct = 0;
    this.nov = 0;
    this.dec = 0;
  }
  id: number;
  jan: number;
  feb: number;
  mar: number;
  apr: number;
  may: number;
  jun: number;
  jul: number;
  aug: number;
  sep: number;
  oct: number;
  nov: number;
  dec: number;
  year: number;
  type: ReportType;
  report: FinancialType;

  created_at: Date;
  updated_at: Date;
}

export class Setting {
  id: number;
  key: string;
  value: string;
  onlyBackend: number;
}

export class Avatar {
  user_id: number;
  image: string;
}

export class ConnectedEntity extends Entity {
  isExpanded: boolean = false;
  counter: number;
}

export class BulkEntdata extends Entdata {
  changeAttr: boolean = false;
}

export class BulkItemsBatch {
  items_ids: string[];
  values: BulkEntdata;
}

export class ProductionLine {
  production_line_id: string;
  name: string;
  time: number;
}

export class ChannelChat {
  id: number;
  user_id: number;
  user: User;
  entdata_id: number;
  message: string;
  type: string;
  file_path: string;
  details: ChannelDetail[];
  myreaction: ChannelReaction;
  created_at: Date;
  updated_at: Date;

  showReactions: boolean = false;
}

export class ChannelDetail {
  id: number;
  user_id: number;
  user: User;
  channel_chat_id: number;
  reaction: ChannelReaction;
  created_at: Date;
  updated_at: Date;
}

export class AutocompleteOption {
  item: any;
  label: string;
}

export interface GridColumn {
  desc: string;
  key: string;
}

export class DashboardNums {
  approvals: number;
  tasks: number;
  meetings?: number;
  channelMsg?: number;
  quotes?: number;
  opportunities?: number;
  serviceTickets?: number;
  invoices?: number;
}

export class AdditionalBtn {
  icon: string;
  text: string;
  action: string;
  color: string;
  itemAction: string;
}

export class CalendarTask {
  id: number;
  description: string;
  assignee: number;
  start_date: string;
  end_date: string;
  duration: string;
  status: string;
  calendar: number;
  recurring: string;
}

export class CalendarEntityEvent {
  id: number;
  title: string;
  description: string;
  owner_id: number;
  owner: User;
  calendar_id: number;
  start_date: string;
  end_date: string;
  calendar_event_participants: CalendarEventParticipant[];

  created_at: Date;
  updated_at: Date;
}

export class CalendarEventParticipant {
  id: number;
  user_id: number;
  user: User;
}

export class CalendarShift {
  id: number;
  description: string;
  shift_participants: CalendarShiftParticipant[];
  start_date: string;
  duration: number;
  calendar_id: number;
}

export class CalendarMeeting {
  id: number;
  description: string;
  meetings_participants: CalendarMeetingsParticipant[];
  start_date: string;
  duration: number;
  calendar_id: number;
}

export class CalendarAbsence {
  id: number;
  description: string;
  type: string;
  start_date: string;
  end_date: string;
  status: string;
  employee_id: number;
  employee_firstname: string;
  employee_lastname: string;
}

export class CalendarProductionOrder {
  id: number;
  month: string;
  start_date: string;
  end_date: string;
  status: string;
  product_name: string | null;
}

export class CalendarShiftParticipant {
  user_id: number;
}

export class CalendarMeetingsParticipant {
  user_id: number;
}

export class userSearchFilterPref {
  entfield_id: number;
}

export class ChartData {
  id?: number;
  title: string;
  entity?: Entity;
  entity_id: number;
  entfield?: Field;
  entfield_id: number;
  selected_function: string;
  control_field_id: number;
  control_field?: Field;
  date_creation_mode: string;
  creation_from: string;
  creation_to: string;
  status_change_mode: string;
  status_change_from: string;
  status_change_to: string;
  labels?: string[];
  data?: number[];
  chart_type: string;
}

export class SavedColumns {
  id?: number;
  user_id: number;
  title: string;
  selected_columns: string;
  entity_code: string;
  created_at: Date;
  updated_at: Date;
}

export class StatisticsReport {
  id?: number;
  title: string;
  charts: ChartData[] | MatTableDataSource<ChartData>;
}

export class CostCenter {
  id: number;
  title: string;
  parentCostCenter: number;
  parentCostCenterTitle?: string;
}

export class SpDocs {
  id: number;
  code: string;
  spdoctype_id: number;
  post_address: string;
  del_address: string;
  year: number;
  created_at: Date;
  updated_at: Date;
  owner_id: number;
  to_wh_id: number;
  transactor_id: number;
  total_price: number;
  total_vat: number;
  issued_at: number;
  use_period_id: number;
}

export class SpDocLines {
  id: number;
  spdoc_id: number;
  spdoctype_id: number;
  product_id: number;
  qty1: number;
  qty2: number;
  created_at: Date;
  updated_at: Date;
  warehouse_id: number;
  item_price: number;
  vat: number;
  discount: number;
  discount2: number;
  unit: number;
}

export class MoneyDocs {
  id: number;
  code: string;
  moneydoctype_id: number;
  moneyaccount_id: number;
  transactor_id: number;
  owner_id: number;
  year: number;
  paid_at: Date;
  total_price: number;
  created_at: Date;
  updated_at: Date;
  use_period_id: number;
  to_acc_id: number;
  remarks: string;
}

export interface ProductValidation {
  validated: boolean;
}

export interface WorkflowTemplateStep {
  id: number;
  workflowTemplateId: number;
  previousStep: number | null;
  entdataStep: number | null;
  questionEntfieldId: number | null;
  entityChoiceId: number | null;
  actorId: number;
  stepType: WorkflowTemplateStepTypeEnum;
  actionTitle: string;
  createdAt: Date;
  updatedAt: Date;
  actor: ActionUser | null;
  questionEntfield: QuestionEntfield | null;
  chosenEntity: EntitySummary;
}

export interface WorkflowTemplate {
  id: number;
  title: string;
  status: WorkflowTemplateStatusEnum;
  createdAt: Date;
  updatedAt: Date;
  workflowTemplateSteps: WorkflowTemplateStep[];
}

export interface ActionUser {
  id: number;
  firstname: string;
  lastname: string;
  nickname: string;
}

export interface QuestionEntfield {
  id: number;
  entityId: number;
  labelGr: string;
  labelEn: string;
  entity: EntitySummary;
}

export interface EntitySummary {
  id: number;
  entityCode: string;
  nameGr: string;
  nameEn: string;
}

export interface Workflow {
  id: number;
  title: string;
  ownerId: number;
  owner: ActionUser;
  status: WorkflowStatusEnum;
  workflowTemplateId: number;
  createdAt: Date;
  updatedAt: Date;
  workflowSteps: WorkflowStep[];
  workflowTemplate: WorkflowTemplate;
}

export interface WorkflowDetails extends Workflow {
  workflowSteps: WorkflowStepDetails[];
}

export interface WorkflowStep {
  id: number;
  workflowId: number;
  workflowTemplateStepId: number;
  status: WorkflowStatusEnum;
  connectedObjectId: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface WorkflowStepDetails extends WorkflowStep {
  workflowTemplateStep: WorkflowTemplateStep;
}

export interface FileCollectionFile {
  id: number;
  entdataId: number;
  entfieldId: number;
  fileName: string;
  fileSize: number;
}
