import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpinnerType } from '../common/enums';
import { Question, ReturnData } from '../model';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuestionService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public answerQuestion(question: Question): Observable<ReturnData<any>> {
    return this.delete<ReturnData<any>>(
      this.apiUrl + 'entity/questions/read/' + question.id,
      SpinnerType.None
    );
  }

  public getQuestions(): Observable<Question[]> {
    return this.get<Question[]>(this.apiUrl + 'questions');
  }

  public postQuestions(
    questions: Question[]
  ): Observable<ReturnData<Question[]>> {
    return this.post<ReturnData<Question[]>>(
      this.apiUrl + 'questions',
      questions
    );
  }
}
