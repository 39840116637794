import {
  Component,
  DestroyRef,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FileCollectionFile } from '../../../../../model';
import { FileUploadService } from '../../../../../services/file-upload.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NotificationService } from '../../../../../services/notification.service';
import { LayoutService } from '../../../../../services/layout.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-file-collection',
  templateUrl: './file-collection.component.html',
  styleUrls: ['./file-collection.component.css'],
})
export class FileCollectionComponent implements OnInit {
  @Input() ITEM: any;
  @Input() ITEMID: any;
  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  uploadedFiles: FileCollectionFile[] = [];

  constructor(
    private fileUploadService: FileUploadService,
    private destroyRef: DestroyRef,
    private notificationService: NotificationService,
    public layoutService: LayoutService
  ) {}

  ngOnInit() {
    if (this.ITEMID != 'new') {
      this.getUploadedFiles();
    }
  }

  prepareFilesList(files: Array<any>) {
    this.fileUploadService
      .uploadFileCollection(files, this.ITEMID, Number(this.ITEM.id))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        (res) => {
          this.uploadedFiles = [...this.uploadedFiles, ...res];
          this.notificationService.showSnackbarMessage(
            'Messages.uploadMultipleFilesSuccess'
          );
        },
        (error) => {
          this.notificationService.showSnackbarMessage(error.error.error);
        }
      );
    this.fileDropEl.nativeElement.value = '';
  }

  private getUploadedFiles() {
    this.fileUploadService
      .getFileCollection(this.ITEMID, Number(this.ITEM.id))
      .pipe(take(1))
      .subscribe((res) => (this.uploadedFiles = res));
  }

  downloadFile(id: number, name: string) {
    this.fileUploadService
      .downloadFileFromFileCollection(id)
      .pipe(take(1))
      .subscribe((file) => {
        this.fileUploadService.downloadToDevice(file, name);
      });
  }

  deleteFile(id: number) {
    this.fileUploadService
      .deleteFileFromFileCollection(id)
      .pipe(take(1))
      .subscribe(() => {
        this.uploadedFiles = this.uploadedFiles.filter(
          (file) => file.id !== id
        );
        this.notificationService.showSnackbarMessage(
          'Messages.deleteFileFromFileCollectionSuccess'
        );
      });
  }
}
