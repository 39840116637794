import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DurationHelper } from 'src/app/common/helper';
import { Sprint, TaskDto } from 'src/app/model';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-task-list-table',
  templateUrl: './task-list-table.component.html',
  styleUrls: ['./task-list-table.component.css'],
})
export class TaskListTableComponent {
  @Input() TASKS: TaskDto[];
  @Input() SHOWMOVESPRINT = false;
  @Input() SPRINTS: Sprint[] = [];
  @Input() PROJECTID = 0;
  @Output() refreshData = new EventEmitter();
  @Output() selectTask = new EventEmitter<any>();
  constructor(
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private projectService: ProjectService
  ) {}

  public sortData(sort: Sort) {
    this.TASKS = this.TASKS.sort(function (a, b) {
      return b[sort.active] - a[sort.active];
    });

    this.TASKS = this.TASKS.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id':
          return this.compare(a.id, b.id, isAsc);
        case 'task_desc':
          return this.compare(a.task_desc, b.task_desc, isAsc);
        case 'task_assignee':
          return this.compare(a.task_assignee, b.task_assignee, isAsc);
        case 'task_status':
          return this.compare(a.task_status, b.task_status, isAsc);
        case 'task_startdate':
          return this.compare(a.task_startdate, b.task_startdate, isAsc);
        case 'task_duration':
          return this.compare(a.task_duration, b.task_duration, isAsc);
        case 'task_group':
          return this.compare(a.task_group.name, b.task_group.name, isAsc);
        default:
          return 0;
      }
    });
  }

  private compare(
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getStatusBackground(task: any): string {
    if (task.task_status === 'Done') {
      return 'doneBack';
    } else if (task.task_status === 'Backlog') {
      return 'backlogBack';
    } else if (task.task_status === 'Working on it') {
      return 'workingBack';
    } else {
      return 'elseBack';
    }
  }

  getStatusIdTd(task: any): string {
    if (task.task_status === 'Done') {
      return 'taskIdDone';
    } else if (task.task_status === 'Backlog') {
      return 'taskIdTBacklog';
    } else if (task.task_status === 'Working on it') {
      return 'taskIdWorking';
    } else {
      return 'taskIdElse';
    }
  }

  isOverdue(task: any): boolean {
    if (task.task_startdate != null && task.task_duration != null) {
      const durationHelper: DurationHelper = new DurationHelper();

      let addminutes = durationHelper.durationToMinutes24h(task.task_duration);

      let enddate: Date = new Date(task.task_startdate);
      enddate.setMinutes(enddate.getMinutes() + addminutes);

      return enddate < new Date() && task.task_status != 'Done';
    } else {
      return false;
    }
  }

  public openTask(task: any) {
    this.selectTask.emit(task);
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  public moveTaskToSprint(sprint: Sprint, task) {
    this.projectService
      .addTaskToSprint(this.PROJECTID, sprint, task)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.success) {
          this.refreshData.emit();
        }
      });
  }
}
